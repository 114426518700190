import LoadingScreen from '../../components/Screen/LoadingScreen';
import PropTypes from 'prop-types';
import React from 'react';
import { useAppSelector } from '../../hooks/redux-hooks';

interface Props {
  children: any;
}

const LoadingController: React.FunctionComponent<Props> = (props:Props) => {

  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);

  return (
    <>
      {
        (loadingData.isLanguageChanging || ((userData.isLoggedIn || !loadingData.isLanguageLoaded) && (!loadingData.isLanguageLoaded || !loadingData.isUserLoaded || !userData.isLoggedIn))) ? (
          <LoadingScreen />
        ) : null
      }
      {props.children}
    </>
  );
};

LoadingController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default LoadingController;