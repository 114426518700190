import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  isFailed: any;
  isHighDemand: any;
  isLanguageChanging: any;
  isLanguageLoaded: any;
  isUserLoaded: any;
  isUserDataLoaded: any;
  isUserLoggouting: any;
  userLoadedStatus: any;
  reInit: any;
};

const initialState: ArrayModel = {
  isFailed: false,
  isHighDemand: false,
  isLanguageChanging: false,
  isLanguageLoaded: false,
  isUserLoaded: false,
  isUserDataLoaded: false,
  isUserLoggouting: false,
  userLoadedStatus: 0,
  reInit: false,
};

const slice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setIsFailed(state, action: PayloadAction<Model>){
      state.isFailed = action.payload;
    },
    setIsHighDemand(state, action: PayloadAction<Model>){
      state.isHighDemand = action.payload;
    },
    setIsLanguageChanging(state, action: PayloadAction<Model>){
      state.isLanguageChanging = action.payload;
    },
    setIsLanguageLoaded(state, action: PayloadAction<Model>){
      state.isLanguageLoaded = action.payload;
    },
    setIsUserLoaded(state, action: PayloadAction<Model>){
      state.isUserLoaded = action.payload;
    },
    setIsUserDataLoaded(state, action: PayloadAction<Model>){
      state.isUserDataLoaded = action.payload;
    },
    setIsUserLoggouting(state, action: PayloadAction<Model>){
      state.isUserLoggouting = action.payload;
    },
    setUserLoadedStatus(state, action: PayloadAction<Model>){
      state.userLoadedStatus = action.payload;
    },
    updateUserLoadedStatus(state, action: PayloadAction<Model>){
      state.userLoadedStatus = state.userLoadedStatus + action.payload;
    },
    setReInit(state, action: PayloadAction<Model>){
      state.reInit = action.payload;
    },
  }
});

export default slice;