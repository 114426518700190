type objectType = {
  [key: string]: any;
};

const t = (value: any) => { return value };

const menuItems: objectType = {
  main: [
    {
      title: t('dashboard'),
      to: '/dashboard',
      icon: 'dashboard',
      requirements: [
        ["customSetting", "addons", ["app", "app_dashboard"]],
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "teacher",
        "director",
      ],
      order: 1,
    },
    {
      title: t('timeline'),
      to: '/timeline',
      icon: 'timeline',
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 2,
    },
    {
      title: t('children'),
      to: '/children',
      icon: 'people',
      requirements: [
        "children",
      ],
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 3,
    },
    {
      title: t('finance'),
      to: '/finance',
      icon: 'finance',
      requirements: [
        //"subscription",
        "finance",
      ],
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
      ],
      order: 4,
    },
    {
      title: t('classbook'),
      to: '/classbook',
      icon: 'classbook',
      /*
      submenus: [
        {
          title: 'week_view',
          to: '/classbook/weekview',
          icon: '',
        },
        {
          title: 'day_view',
          to: '/classbook/dayview',
          icon: '',
        },
      ],
      */
      requirements: [
        "subscription"
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "teacher",
        "director",
      ],
      order: 5,
    },
    {
      title: t('excuse_notes'),
      to: '/excuse-notes',
      icon: 'calendar-excuse-notes',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 6,
    },
    {
      title: t('attendance'),
      to: '/attendance',
      icon: 'calendar-attendance',
      validations: [
        "attendance",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "teacher",
        "director",
      ],
      order: 7,
    }, 
    {
      title: t('press_reports'),
      to: '/press-report',
      icon: 'printer',
      validations: [
        "children",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "teacher",
        "director",
      ],
      order: 8,
    }, 
    {
      title: t('photo_archive'),
      to: '/photo-archive',
      icon: 'photo-archive',
      requirements: [
        "premium",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
      ],
      order: 9,
    },
    {
      title: t('gallery'),
      to: '/gallery',
      icon: 'gallery',
      requirements: [
        "premium",
      ],
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
      ],
      order: 10,
    },  
    {
      title: t('substitutions'),
      to: '/substitutions',
      icon: 'calendar-substitutions',
      requirements: [
        "substitutions",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 11,
    },
    {
      title: t('calendar'),
      to: '/calendar',
      icon: 'calendar',
      requirements: [
        "calendar",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 12,
    },
    {
      title: t('meals'),
      to: '/meals',
      icon: 'meals',
      requirements: [
        "mealMenu",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 13,
    },
    {
      title: t('shop'),
      to: '/shop',
      icon: 'shop',
      requirements: [
        "shop",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
      ],
      order: 14,
    },
    {
      title: t('stock'),
      to: '/stock',
      icon: 'stock',
      requirements: [
        "stock",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "director",
      ],
      order: 15,
    },
    {
      title: t('timetable'),
      to: '/timetable',
      icon: 'calendar-timetable',
      requirements: [
        "timetable",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 16,
    },
    {
      title: t('clubs'),
      to: '/clubs',
      icon: 'clubs',
      requirements: [
        "club",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 17,
    },
    {
      title: t('communication'),
      to: '/communication',
      icon: 'communication',
      requirements: [
        "communication",
        "subscription",
      ],
      validations: [
        "communication",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 18,
    },
    {
      title: t('contacts'),
      to: '/contacts',
      icon: 'contacts',
      requirements: [
        "contacts",
      ],
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "parent",
      ],
      order: 19,
    },
    {
      title: t('employees'),
      to: '/employees',
      icon: 'employees',
      requirements: [
        "employees",
      ],
      hasFilters: true,
      isEnabled: true,
      isVisible: true,
      canBeHome: true,
      roles: [
        "director",
      ],
      order: 20,
    },
    {
      title: t('bakalari'),
      to: '/bakalari',
      icon: 'bakalari',
      requirements: [
        "bakalariUrl",
      ],
      validations: [
        "bakalariUrl",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 21,
    },
    {
      title: t('mealmenu'),
      to: '/mealmenu',
      icon: 'mealmenu',
      requirements: [
        "mealmenuUrl",
      ],
      validations: [
        "mealmenuUrl",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 22,
    },
  ],
  profile: [
    {
      title: t('settings'),
      to: '/settings',
      icon: 'cog',
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 1,
    },
    {
      title: t('premium'),
      to: '/settings/premium',
      icon: 'diamond-outlined',
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
      ],
      order: 2,
    },
    {
      title: t('gdpr'),
      to: '/about/gdpr',
      icon: 'gdpr-about',
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 3,
    },
    {
      title: t('about_application'),
      to: '/about/app',
      icon: 'info-circle-outlined',
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 4,
    },    
  ],
  settings: [
    {
      title: t('back'),
      to: '/back',
      icon: 'arrow-left',
      validations: [
        "back",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 1,
    },
    {
      title: t('profile'),
      to: '/settings/profile',
      icon: 'user-circle-outlined',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 1,
    },
    {
      title: t('security'),
      to: '/settings/security',
      icon: 'security',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 2,
    },
    {
      title: t('employee'),
      to: '/settings/employee',
      icon: 'person',
      requirements: [
        "employee",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "teacher",
        "director",
      ],
      order: 3,
    },
    {
      title: t('wages'),
      to: '/settings/wages',
      icon: 'wage',
      requirements: [
        "employee",
        "subscription",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "teacher",
        "director",
      ],
      order: 4,
    },
    {
      title: t('privacy'),
      to: '/settings/privacy',
      icon: 'privacy',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 5,
    },
    {
      title: t('premium'),
      to: '/settings/premium',
      icon: 'diamond-outlined',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
      ],
      order: 6,
    },
    {
      title: t('notifications'),
      to: '/settings/notifications',
      icon: 'bell-outlined',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 7,
    },
    {
      title: t('cookies'),
      to: '/settings/cookies',
      icon: 'cookie',
      requirements: [
        "cookies",
      ],
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 8,
    },
    {
      title: t('customizations'),
      to: '/settings/customizations',
      icon: 'customizations',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 9,
    },
    {
      title: t('addons'),
      to: '/settings/addons',
      icon: 'addons',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 10,
    },
    {
      title: t('data_management'),
      to: '/settings/data-management',
      icon: 'data-management',
      hasFilters: false,
      isEnabled: true,
      isVisible: true,
      canBeHome: false,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
      order: 11,
    },
  ],
  communication: [],
};

export default menuItems;