import slice from '../slices/loading.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setIsFailed = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsFailed(data));
  }
};

export const setIsHighDemand = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsHighDemand(data));
  }
};

export const setIsLanguageChanging = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsLanguageChanging(data));
  }
};

export const setIsLanguageLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsLanguageLoaded(data));
  }
};

export const setIsUserLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsUserLoaded(data));
  }
};

export const setIsUserDataLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsUserDataLoaded(data));
  }
};

export const setIsUserLoggouting = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsUserLoggouting(data));
  }
};

export const setUserLoadedStatus = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setUserLoadedStatus(data));
  }
};

export const updateUserLoadedStatus = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateUserLoadedStatus(data));
  }
};

export const setReInit = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setReInit(data));
  }
};