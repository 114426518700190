import config from '../constants/config';
import httpResult from 'src/constants/httpresult';
import { createAxios } from './axios.service';
import { getAppAccessToken, getAppApiServer, getAppData, getAppLanguage } from '../utils/useApp';
import { ObjectType } from 'src/types/types';

const version: string = config.APP_VERSION;
const platform: string = config.APP_PLATFORM;

const get = (url: string, headers: ObjectType = {}, params: ObjectType = {}) => {
  const apiURL = getAppApiServer();
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    ...headers,
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
  };
  const axios = createAxios(apiURL, axiosHeaders, params);
  return axios.get(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN && !url.includes('user/sso-login')) {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const getBearer = (url: string, bearer: string, headers: ObjectType = {}, params: ObjectType = {}) => {
  const apiURL = headers.apiUrl ? headers.apiUrl : getAppApiServer();
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    ...headers,
    "Authorization": `Bearer ${bearer}`,
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
  };
  const axios = createAxios(apiURL, axiosHeaders, params);
  return axios.get(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    throw e;
  });
};

const getRoot = (rootURL: string, url: string, headers: ObjectType = {}, params: ObjectType = {}) => {
  const axiosHeaders = {
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.get(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    throw e;
  });
};

const getDefaultRoot = (rootURL: string, url: string, headers: ObjectType = {}, params: ObjectType = {}) => {
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.get(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    throw e;
  });
};

const post = (url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const apiURL = headers.apiUrl ? headers.apiUrl : getAppApiServer();
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    ...headers,
    "Authorization": getAppAccessToken() === null ? '' : url !== 'user/login' ? `Bearer ${getAppAccessToken()}` : '',
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
  };
  const axios = createAxios(apiURL, axiosHeaders, params);
  return axios.post(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN && url !== 'user/login') {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const postRoot = (rootURL: string, url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const axiosHeaders = {
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.post(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN && url !== 'user/login') {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const postDefaultRoot = (rootURL: string, url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    "Authorization": getAppAccessToken() === null ? '' : url !== 'user/login' ? `Bearer ${getAppAccessToken()}` : '',
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.post(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN && url !== 'user/login') {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const put = (url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const apiURL = getAppApiServer();
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    ...headers,
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
  };
  const axios = createAxios(apiURL, axiosHeaders, params);
  return axios.put(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const putRoot = (rootURL: string, url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const axiosHeaders = {
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.put(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const putDefaultRoot = (rootURL: string, url: string, data: any, headers: ObjectType = {}, params: ObjectType = {}) => {
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders, params);
  return axios.put(url, data).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        if(window.location.pathname !== "/auth/logout") {
          window.location.href = '/auth/logout';
        }
      }
    }
    throw e;
  });
};

const remove = (url: string, headers: ObjectType = {}) => {
  const apiURL = getAppApiServer();
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    ...headers,
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
  };
  const axios = createAxios(apiURL, axiosHeaders);
  return axios.delete(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        window.location.href = '/auth/logout';
      }
    }
    throw e;
  });
};

const removeRoot = (rootURL: string, url: string, headers: ObjectType = {}) => {
  const axiosHeaders = {
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders);
  return axios.delete(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        window.location.href = '/auth/logout';
      }
    }
    throw e;
  });
};

const removeDefaultRoot = (rootURL: string, url: string, headers: ObjectType = {}) => {
  const appTheme = getAppData().theme;
  const axiosHeaders = {
    "Authorization": getAppAccessToken() === null ? '' : `Bearer ${getAppAccessToken()}`,
    "Content-Type": "application/json",
    "Content-Language": headers.customLanguage ? headers.customLanguage : getAppLanguage(),
    "version": version,
    "platform": platform,
    "theme": appTheme,
    ...headers,
  };
  const axios = createAxios(rootURL, axiosHeaders);
  return axios.delete(url).then((result: any) => {
    return result;
  }).catch((e: any) => {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        window.location.href = '/auth/logout';
      }
    }
    throw e;
  });
};

const httpService = {
  get,
  getBearer,
  getRoot,
  getDefaultRoot,
  post,
  postRoot,
  postDefaultRoot,
  put,
  putRoot,
  putDefaultRoot,
  remove,
  removeRoot,
  removeDefaultRoot,
};

export default httpService;