import config from '../../../../constants/config';
import NormalButton from '../../../../components/Buttons/NormalButton';
import React from 'react';
import SVG from '../../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { languages } from '../../../../constants/languages';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  aboutAppPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: 'calc(100% - 112px)',
    overflow: 'auto',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    marginTop: 'auto',
    '& > svg': {
      display: 'flex',
      maxWidth: '240px',
      height: '64px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },      
  },
  title: {
    textAlign: 'center',
    marginTop: '16px',
    color: '#666666',
    '& > small': {
      marginLeft: '8px',
      fontSize: '0.8rem',
    }, 
  },
  text: {
    marginTop: '16px',
    textAlign: 'center',
    color: theme.colors.black,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '16px',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },      
  },
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
    clipPath: 'ellipse(60% 90% at 50% 100%)',
    backgroundColor: '#00A2FF',
    marginTop: 'auto',  
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  
  const handleContact = () => {
    window.location.href = 'mailto: support@twigsee.com';
  };
  
  const handleKnowledgeBase = () => {
    const langs: any = languages;
    const url: any = langs.find((lang: any) => lang.acronym === languageData.language).knowledgeBaseLink;
    window.open(url);
  }; 
  
  return (
    <div className={classes.aboutAppPage}>
      <div className={classes.wrapper}>
        <SVG src={config.APP_LOGO}/>
        <span className={classes.title}>
          {t("version")}: {config.APP_VERSION}
          {
            config.APP_ENVIRONMENT !== "production" ? (
              <small>({config.APP_ENVIRONMENT})</small>
            ) : null
          }
        </span>
        <p className={classes.text}>{t('app_powered')} {t('twigsee_copyright')}</p>
      </div>
      <div className={classes.buttonWrapper}>
        <NormalButton buttonType={"darkBlue"} onClick={handleContact} dataCy="contactButton">
          {t('contact_us')}
        </NormalButton>
        <NormalButton buttonType={"darkBlueOutlined"} onClick={handleKnowledgeBase} dataCy="knowledgeButton">
          {t("knowledge_base")}
        </NormalButton>
      </div>
      <div className={classes.block}/>
    </div>
  );
};

export default PageTemplate;