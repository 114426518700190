import * as AuthService from '../../../../services/auth.service';
import config from 'src/constants/config';
import httpResult from 'src/constants/httpresult';
import { getAppApiServer, getAppData, removeAppAccessToken, setAppData } from 'src/utils/useApp';
import { resetClassbook } from 'src/store/actions/classbook.actions';
import { resetFirebaseNotifications } from 'src/store/actions/firebasenotification.actions';
import { resetPostCreateModal } from 'src/store/actions/modals.actions';
import { setIsAutoLoggedOff, setIsLoggedIn, setUserObject } from 'src/store/actions/user.actions';
import { setIsFailed, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setIsUserLoggouting } from 'src/store/actions/loading.actions';
import { setStockCart } from 'src/store/actions/stock.actions';

const checkToken = async (dispatch: any, userData: any) => {
  
  const savedData = getAppData();
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  try {
    const result: any = await AuthService.verifyToken();
    if(result && result.status === httpResult.SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (e: any) {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        AuthService && AuthService.logout().then(() => {
          const savedUsers = savedData.users ? savedData.users : [];
          const newUsersData = savedUsers.length === 1 ? [] : savedUsers.map((item: any) => { if((useApiServers ? (item.userID === userData.userObject.userID && item.apiServer === getAppApiServer()) : item.userID === userData.userObject.userID)) { return {...item, accessToken: false}; } else { return item; } });
          const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
          setAppData(newData);
          removeAppAccessToken();
          dispatch(setIsLanguageLoaded(true));
          dispatch(setIsUserLoaded(false));
          dispatch(setIsUserDataLoaded(false));
          dispatch(setIsUserLoggouting(true));
          dispatch(setIsFailed(false));
          dispatch(setIsLoggedIn(false));
          dispatch(setIsAutoLoggedOff(true));
          dispatch(setUserObject({}));
          dispatch(resetClassbook());
          dispatch(resetPostCreateModal());
          dispatch(setStockCart([]));
          dispatch(resetFirebaseNotifications());
        });
      }
    } else {
      return false; 
    }
  };
};

export default checkToken;