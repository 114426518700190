import React, { useRef } from 'react';
import RenderMenuItem from './RenderMenuItem';
import SVG from 'src/components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { validateItemRequirements } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useLocation } from 'react-router';

const useStyles = createUseStyles((theme: any) => ({
  leftMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    width: '240px',
    margin: '30px 0px auto 0px',
    maxHeight: '100%',
    overflow: 'visible',
    height: '100%',
  },
  handleItem: {
    display: 'flex',
    width: 'calc(100% - 32px)',
    borderRadius: '24px',
    backgroundColor: theme.colors.white,
    color: theme.colors.primaryBlue[500],
    boxShadow: theme.shadows[2],
    fontSize: '12px',
    padding: '8.5px 8px',
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
    '& svg': {
      color: 'inherit',
      height: "20px",
      width: "20px",
    },
  },
}));

type ItemsType = {
  items: any;
  dataCy?: any;
};

const LeftMenu: React.FunctionComponent<ItemsType> = ({ items, dataCy }) => {
  
  const classes = useStyles();
  const location = useLocation();
  const itemsOpenHandle: any = {};
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const leftMenuWrapper: any = useRef(null);

  const [state, setState] = useStates({
    currentPage: 0,
    leftMenuWrapperHeight: 0,
  });

  const handlePrevCurrentPage = () => {
    setState("currentPage", state.currentPage - 1);
  };

  const handleNextCurrentPage = () => {
    setState("currentPage", state.currentPage + 1);
  };

  useEffect(() => {
    if(leftMenuWrapper.current) {
      setTimeout(() => {
        const leftMenuWrapperHeight = leftMenuWrapper.current ? (leftMenuWrapper.current.clientHeight ? leftMenuWrapper.current.clientHeight : 0) : 0;
        setState("leftMenuWrapperHeight", leftMenuWrapperHeight);
        setState("currentPage", 0);
      }, 100);
    }
  }, [browserData, setState], [browserData, leftMenuWrapper]);


  const menuItems = items.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null && (item.isVisible || (!item.isVisible && item.to === location.pathname)));

  menuItems.sort((a: any, b: any) => { return a.order - b.order; });

  const buttonHeight = 48;
  const visibleItemsCount = Math.floor(state.leftMenuWrapperHeight / buttonHeight) - (state.currentPage === 0 ? 1 : 2);
  const visibleItems = visibleItemsCount > 0 ? visibleItemsCount : menuItems.length;
  
  return (
    <div className={classes.leftMenu} ref={leftMenuWrapper} data-cy={isCypress() ? dataCy : null}>
      {
        menuItems.slice(0 + (visibleItems * (state.currentPage - 1)), visibleItems + (visibleItems * (state.currentPage - 1))).length > 0 ? (
          <Button className={classes.handleItem} onClick={handlePrevCurrentPage}>
            <SVG src="chevron-up"/>
          </Button>
        ) : null
      }
      {
        menuItems.slice(0 + (visibleItems * state.currentPage) + (state.currentPage === 0 ? 0 : 1), visibleItems + (visibleItems * state.currentPage) + (state.currentPage === 0 ? 0 : 1)).map((node: any, key: any) => (
          <RenderMenuItem node={node} parent={null} itemsOpenHandle={itemsOpenHandle} key={`k_${key}`}/>   
        ))
      }
      {
        (menuItems.slice(0 + (visibleItems * (state.currentPage + 1)), visibleItems + (visibleItems * (state.currentPage + 1))).length > 0 && (menuItems.length - (visibleItems * (state.currentPage + 1))) >= 0) ? (
          <Button className={classes.handleItem} onClick={handleNextCurrentPage}>
            <SVG src="chevron-down"/>
          </Button>
        ) : null
      }
    </div>
  );
}

export default LeftMenu;