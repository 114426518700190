import * as AuthService from '../../../../services/auth.service';
import Button from '@mui/material/Button';
import config from 'src/constants/config';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getAppApiServer, getAppData, removeAppAccessToken, setAppData } from 'src/utils/useApp';
import { resetClassbook } from 'src/store/actions/classbook.actions';
import { resetFirebaseNotifications } from 'src/store/actions/firebasenotification.actions';
import { resetPostCreateModal } from '../../../../store/actions/modals.actions';
import { setIsFailed, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setIsUserLoggouting } from 'src/store/actions/loading.actions';
import { setIsLoggedIn, setUserObject } from 'src/store/actions/user.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  moremenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

const LogoutMenuItem: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const savedData = getAppData();
  const userData = useAppSelector((state: any) => state.user);
  const classes = useStyles();
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;
  
  const handleLogout = () => {
    const savedUsers = savedData.users ? savedData.users : [];
    const newUsersData = savedUsers.length === 1 ? [] : savedUsers.map((item: any) => { if((useApiServers ? (item.userID === userData.userObject.userID && item.apiServer === getAppApiServer()) : item.userID === userData.userObject.userID)) { return {...item, accessToken: false}; } else { return item; } });
    const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
    setAppData(newData);
    removeAppAccessToken();
    dispatch(setIsLanguageLoaded(false));
    dispatch(setIsUserLoaded(false));
    dispatch(setIsUserDataLoaded(false));
    dispatch(setIsUserLoggouting(true));
    dispatch(setIsFailed(false));
    dispatch(setIsLoggedIn(false));
    dispatch(setUserObject({}));
    dispatch(resetClassbook());
    dispatch(resetPostCreateModal());
    dispatch(setStockCart([]));
    dispatch(resetFirebaseNotifications());
  };
  
  const handleClickLogout = () => {
    dispatch(setIsUserLoggouting(true));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  };
  
  return (
    <Button className={classes.moremenuItem} onClick={handleClickLogout}>
      <SVG src="logout"/>
      <span>{t('logout')}</span>
      <span className={classes.chevron}>
        <SVG src="chevron-right"/>
      </span> 
    </Button>
  );
}

export default LogoutMenuItem;