import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { arrayBufferToBase64, base64toBlob } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setAttachments } from '../../../../store/actions/medias.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  attachmentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pdfViewer: {
    width: '80%',
    height: '100%',
    maxWidth: '80%',
  },
  unsupported: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.colors.white,
    '& > svg': {
      width: '96px',
      height: '96px',
      marginBottom: '5px',
    },
    '& > p': {
      maxWidth: '80%',
      marginBottom: '0',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
}));

type RenderAttachmentType = {
  mediaData: any;
  setIsDownloadable: any;
  handleSecureClick: any;
}

const RenderAttachment: React.FunctionComponent<RenderAttachmentType> = ({ mediaData, setIsDownloadable,  handleSecureClick }) => {

  const { t } = useTranslation();
  const mediasData = useAppSelector((state: any) => state.medias);
  const mediaID = useAppSelector((state: any) => state.modals).calendarGalleryModal.mediaID;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const dispatch = useAppDispatch();
  const classes = useStyles();  
  const fileType = (mediaData.name).split('.').pop();

  const [state, setState] = useStates({
    mediaB64: mediasData.attachments.filter((attachment: any) => attachment.id === mediaID).length === 0 ? null : mediasData.attachments.find((attachment: any) => attachment.id === mediaID).base64,
    mediaUrl: null,
    isAttachmentLoaded: false,
    lastMediaID: null,
  });
  
  useEffect(() => {
    setState("mediaB64", null);
    setState("isAttachmentLoaded", false);
  }, [mediaData, setState], [mediaData]);
  
  useEffect(() => {
    if(!state.isAttachmentLoaded) {
      let contentType = "application/octet-stream";
      if(fileType === "pdf") {
        contentType = "application/pdf";  
      }
      if(state.mediaB64 !== null) {
        const blob = base64toBlob(state.mediaB64, contentType);
        setState("mediaUrl", URL.createObjectURL(blob));
        setState("isAttachmentLoaded", true);
        setIsDownloadable(true);
        handleSecureClick();
        setState("lastMediaID", mediaID);
      } else {
        timelineService && timelineService.downloadFile(mediaID).then((result: any) => {
          if(result) {
            if(result.data) {
              const base64 = arrayBufferToBase64(result.data);
              const blob = base64toBlob(base64, contentType);
              const attachmentObj = { base64: base64, id: mediaID };
              dispatch(setAttachments(attachmentObj));
              setState("mediaUrl", URL.createObjectURL(blob));
              setState("isAttachmentLoaded", true);
              setIsDownloadable(true);
              handleSecureClick();
              setState("lastMediaID", mediaID);
            } else {
              setState("isAttachmentLoaded", false);
            }
          } else {
            setState("isAttachmentLoaded", false);
          }
        }).catch(() => {
          createNotification(t('something_went_wrong'), 'error');
        });
      }
    }         
  }, [state.isAttachmentLoaded, dispatch, fileType, handleSecureClick, mediaID, setState, setIsDownloadable, state.mediaB64, timelineService, t], [state.isAttachmentLoaded]); 
      
  return (state.isAttachmentLoaded && state.lastMediaID === mediaID) ? (
    <div className={classes.attachmentWrapper}>
      {
        fileType === "pdf" ? (
          <embed className={classes.pdfViewer} src={`${state.mediaUrl}#view=fitW&toolbar=0`}/>
        ) : (
          <div className={classes.unsupported}>
            <SVG src={`${"document-"}${fileType}${""}`} children={<SVG src="file"/>}/>
            <p>{t("cannot_preview")}</p>
          </div>
        )
      }
    </div>  
  ) : (
    <div className={classes.attachmentWrapper}>
      <div className={classes.loading}>
        <CircularProgress className={classes.spinner}/>
      </div>
    </div>
  );
};

export default RenderAttachment;