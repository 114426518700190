import * as AuthService from '../../../services/auth.service';
import config from 'src/constants/config';
import React, { useCallback } from 'react';
import { getAppApiServer, getAppData, removeAppAccessToken, setAppData } from 'src/utils/useApp';
import { resetClassbook } from '../../../store/actions/classbook.actions';
import { resetFirebaseNotifications } from 'src/store/actions/firebasenotification.actions';
import { resetPostCreateModal } from '../../../store/actions/modals.actions';
import { setIsAutoLoggedOff, setIsLoggedIn, setUserObject } from '../../../store/actions/user.actions';
import { setIsFailed, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setIsUserLoggouting } from '../../../store/actions/loading.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';

const Logout: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const savedData = getAppData();
  const userData = useAppSelector((state: any) => state.user);
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  const handleLogout = useCallback(() => {
    if(userData.isLoggedIn) {
      const savedUsers = savedData.users ? savedData.users : [];
      const newUsersData = savedUsers.length === 1 ? [] : savedUsers.map((item: any) => { if((useApiServers ? (item.userID === userData.userObject.userID && item.apiServer === getAppApiServer()) : item.userID === userData.userObject.userID)) { return {...item, accessToken: false}; } else { return item; } });
      const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
      setAppData(newData);
      removeAppAccessToken();
      dispatch(setIsLanguageLoaded(false));
      dispatch(setIsUserLoaded(false));
      dispatch(setIsUserDataLoaded(false));
      dispatch(setIsFailed(false));
      dispatch(setIsLoggedIn(false));
      dispatch(setIsUserLoggouting(true));
      dispatch(setIsAutoLoggedOff(true));
      dispatch(setUserObject({}));
      dispatch(resetClassbook());
      dispatch(resetPostCreateModal());
      dispatch(setStockCart([]));
      dispatch(resetFirebaseNotifications());
      navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
      setTimeout(() => {
        dispatch(setIsLanguageLoaded(true));
      }, 1000);
    } else {
      const savedUsers = savedData.users ? savedData.users : [];
      dispatch(setIsLanguageLoaded(true));
      navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
    }
  }, [dispatch, navigate, userData.isLoggedIn, savedData.notifications, savedData.users, savedData.twigchat, userData.userObject.userID, useApiServers]);

  useEffect(() => {
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  }, [handleLogout], []);
  
  return null;
}

export default Logout;