import * as AuthService from '../../../services/auth.service';
import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Button from '@mui/material/Button';
import Clamp from 'react-multiline-clamp';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import config from 'src/constants/config';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createCypressDataText, isCypress } from '../../../utils/useCypress';
import { createUseStyles } from 'react-jss';
import { getAppApiServer, getAppData, removeAppAccessToken, setAppData } from 'src/utils/useApp';
import { resetClassbook } from '../../../store/actions/classbook.actions';
import { resetFirebaseNotifications } from 'src/store/actions/firebasenotification.actions';
import { resetPostCreateModal, setLanguageModal } from '../../../store/actions/modals.actions';
import { setIsFailed, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setIsUserLoggouting } from '../../../store/actions/loading.actions';
import { setIsLoggedIn, setUserObject } from '../../../store/actions/user.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { getUserSetting } from 'src/utils/useUser';
import { Tooltip } from '@mui/material';
import { setIsOpenWelcomeScreen } from 'src/store/actions/layout.actions';

const useStyles = createUseStyles((theme: any) => ({
  profileMenuWrapper: {
    position: "relative",
  },
  profileMenu: {
    position: "absolute",
    top: '-5px',
    right: '-7px',
    backgroundColor: theme.colors.white,
    width: "200px",
    borderRadius: "20px",
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.profileMenu,
  },
  profileButton: {
    padding: '0px',
    minWidth: 'initial',
    borderRadius: '50%',
    position: 'relative',
    zIndex: theme.zIndex.profileMenuItem, 
  },
  profileImageWrapper: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    zIndex: theme.zIndex.profileMenuItem, 
    '& > svg': {
      width: '16px',
      height: '16px',
      position: 'absolute',
      bottom: '-6px',
      right: '-6px',
      color: theme.colors.yellow[500],
      zIndex: theme.zIndex.profileMenuItem,  
      backgroundColor: theme.colors.white,
      borderRadius: '100%',
      padding: '4px',
      boxShadow: theme.shadows[2],
    },
  },
  profileImage: {
    width: "38px",
    height: "38px",
    borderRadius: "12px",
    margin: "0",
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  menuItem: {
    position: 'relative',
    padding: "12px 15px",
    fontSize: "12px",
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: "center",
    width: '100%',
    minWidth: 'unset',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.colors.black,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: theme.colors.hoverBlack[4],
      cursor: "pointer",
    },
    '&.disabled': {
      color: '#5A5C7B',
      backgroundColor: theme.colors.grey[125],
      cursor: 'default',  
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '20px',
      color: theme.colors.black,
    },
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 15px",
    height: '48px',
  },
  userName: {
    marginBottom: '0px',
    fontSize: "12px",
    fontWeight: "bold",
    maxWidth: 'calc(100% - 48px)',
    color: theme.colors.black,
  },
  languageAcronym: {
    marginRight: "20px",
    marginBottom: "0",
    height: "100%",
    fontWeight: "600",
    fontSize: '13px',
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: '100%',
    padding: '8px 0',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '10px',  
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
      '&:last-of-type': {
        cursor: 'pointer',
        transition: 'all 0.25s',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}));

type ItemsType = {
  items: any;
  dataCy?: any;
};

const ProfileMenu: React.FunctionComponent<ItemsType> = ({ items, dataCy }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const savedData = getAppData();
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  const [state, setState] = useStates({
    isOpen: false,
  });

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isOpen", !state.isOpen);
  };
  
  const handleClickLanguage = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setLanguageModal(settings));
  };

  const handleClickSwitchUser = () => {
    navigate('/auth/switch');
  };
  
  const handleClickAway = () => {
    setState("isOpen", false);
  };
  
  const handleClickItem = (node: any) => {
    setState("isOpen", false);
    navigate(node.to);  
  };

  const handleLogout = () => {
    const savedUsers = savedData.users ? savedData.users : [];
    const newUsersData = savedUsers.length === 1 ? [] : savedUsers.map((item: any) => { if((useApiServers ? (item.userID === userData.userObject.userID && item.apiServer === getAppApiServer()) : item.userID === userData.userObject.userID)) { return {...item, accessToken: false}; } else { return item; } });
    const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
    setAppData(newData);
    removeAppAccessToken();
    dispatch(setIsLanguageLoaded(false));
    dispatch(setIsUserLoaded(false));
    dispatch(setIsUserDataLoaded(false));
    dispatch(setIsUserLoggouting(true));
    dispatch(setIsFailed(false));
    dispatch(setIsLoggedIn(false));
    dispatch(setUserObject({}));
    dispatch(resetClassbook());
    dispatch(resetPostCreateModal());
    dispatch(setStockCart([]));
    dispatch(resetFirebaseNotifications());
  };

  const handleClickLogout = () => {
    dispatch(setIsUserLoggouting(true));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  };

  const handleOnClickVersion = () => {
    dispatch(setIsOpenWelcomeScreen(true));
  };
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.profileMenuWrapper} data-cy={isCypress() ? dataCy : null}>
        <Button className={classes.profileButton} onClick={handleClick} data-cy={isCypress() ? "profileMenuMainButton" : null}>
          <div className={classes.profileImageWrapper}>
            <AuthenticatedImage className={classes.profileImage} thumbLink={typeof userData.userObject.photo === "string" ? userData.userObject.photo : userData.userObject.photo.thumbLink}/>
            {
              userData && userData.userObject.membership && userData.membership.active ? (
                <SVG src="diamond-filled"/>
              ) : null
            }
          </div>
        </Button>
        {
          state.isOpen ? (
            <div className={classes.profileMenu} data-cy={isCypress() ? "profileMenu" : null}>
              <div className={classes.userInfo}>
                <Clamp withTooltip lines={2}><p className={classes.userName}>{userData.userObject.firstname + ' ' + userData.userObject.surname}</p></Clamp>
              </div>
              <Button className={classes.menuItem} onClick={handleClickLanguage} data-cy={isCypress() ? "profileMenuLanguageButton" : null}>
                <p className={classes.languageAcronym}>{languageData.language || 'EN'}</p>
                {t('language')}
              </Button>
              {
                getUserSetting(userData.userSettings, "addons", ["app", "app_user_switching"]) ? (
                  <Button className={classes.menuItem} onClick={handleClickSwitchUser} data-cy={isCypress() ? "profileMenuUserSwitchButton" : null}>
                    <SVG src="user-switch"/>
                    {t('switch_user')}
                  </Button>
                ) : null
              }
              {
                items.map((node: any, key: any) => (node.isEnabled && node.isVisible) ? (
                  <Button key={`k_${key}`} className={classes.menuItem} onClick={() => handleClickItem(node)} data-cy={isCypress() ?"profileMenu" + createCypressDataText(node.title) : null}>
                    <SVG src={node.icon}/>
                    {t(node.title)}
                  </Button>
                ) : null)
              }
              <Button className={classes.menuItem} onClick={handleClickLogout} data-cy={isCypress() ? "profileMenuLogoutButton" : null}>
                <SVG src="logout"/>
                {t('logout')}
              </Button>
              <div className={classes.footerRow}>
                <Tooltip title={t('twigsee_since')} arrow>
                  <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterCopyright` : null}>
                    &copy; {t('twigsee')} {new Date().getFullYear()}
                  </span>
                </Tooltip>
                <span data-cy={isCypress() ? `mainLayoutFooterDivider` : null}>|</span>
                <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterVersion` : null} onClick={handleOnClickVersion}>
                  {t('version')} {config.APP_VERSION}
                </span>
              </div>
            </div>
          ) : null
        }
      </div>
    </ClickAwayListener>
  );
}

export default ProfileMenu;