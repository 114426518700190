import ChildrenSelect from '../Selects/ChildrenSelect';
import CircularProgress from '@mui/material/CircularProgress';
import moment from '../../utils/moment';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { setAttendanceSelectedChildren } from '../../store/actions/attendance.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  spinner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  customChildrenSelect: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      maxWidth: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '85%',
      maxWidth: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  customTopWrapper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
  },
  customSearch: {
    padding: '24px 24px 0 24px',
  },
  customContainer: {
    margin: '0',
  },
  customSchoolList: {
    maxHeight: 'unset !important',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      maxWidth: '80%',
    },
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
}));

type AttendanceType = {
  isLoaded: boolean;
  isReloaded: boolean;
  presentChildren: any;
  classesList: any;
  childrenList: any;
};

const Attendance: React.FunctionComponent<AttendanceType> = ({isLoaded, isReloaded, presentChildren, classesList, childrenList}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const attendanceData = useAppSelector((state: any) => state.attendance);
  const date = attendanceData.date;
  const selectedChildren = attendanceData.selectedChildren;
  const selectedChildrenRef = useRef(selectedChildren);

  useEffect(() => {
    selectedChildrenRef.current = selectedChildren;
  }, [selectedChildren]);
  
  const onClickChild = (e: any, data: any, allSelectedChildren: any) => {
    e.preventDefault();
    const count = selectedChildrenRef.current.filter((theChild: any) => theChild.childID === data.child.childID && theChild.classID === data.class.classID && data.school).length;
    if(count === 0) { 
      const selectedChild = {
        childID: data.child.childID,
        classID: data.class.classID,
        isPresent: allSelectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify({childID: data.child.childID, classID: data.class.classID})).length === 1 ? true : false,
      };
      const changedChildren = [...selectedChildrenRef.current, selectedChild];
      dispatch(setAttendanceSelectedChildren(changedChildren));
      selectedChildrenRef.current = changedChildren;
    } else {
      const changedChildren = selectedChildrenRef.current.map((theChild: any) => {
        if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
          return null;
        } else {
          return theChild; 
        }
      }).filter((item: any) => item !== null);
      dispatch(setAttendanceSelectedChildren(changedChildren));
      selectedChildrenRef.current = changedChildren;
    } 
  };
    
  return isLoaded ? (
    <ChildrenSelect
      onClickChild={onClickChild}
      isMultipleSelect={false}
      isSelectAll={false}
      defaultClasses={classesList.map((item: any) => { return { classID: item.classID, schoolID: item.schoolID }; })}
      defaultChildren={childrenList.map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: item.schoolID }; })}
      defaultClassesData={classesList}
      defaultChildrenData={childrenList}
      selectedChildren={presentChildren}
      mode="attendance"
      isShowAttendanceCount={true}
      isAllowSearch={true}
      isAllowArchived={false}
      isAllowArchivedToggle={false}
      isDisableClick={(!moment(date).isSame(moment(),'day'))}
      customClasses={{
        childrenSelect: classes.customChildrenSelect,
        topWrapper: classes.customTopWrapper,
        search: classes.customSearch,
        container: classes.customContainer,
        schoolList: classes.customSchoolList,
      }}
      customNoResults={
        (
          <div className={classes.notFound}>
            <img src="/resources/images/noresults.png" alt={'no_results'}/>
            <span>{t('no_attendance_day')}</span>
            <p>{t('attendance_not_exist',{day: moment().format("DD.MM.YYYY")})}</p>
          </div>
        )
      }
      isReload={isReloaded}
    />  
  ) : (
    <div className={classes.spinner}>
      <CircularProgress/>
    </div>
  );
};

export default Attendance;