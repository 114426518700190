import appEnvironment from 'src/constants/environment';
import checkToken from './modules/ValidToken';
import config from '../../constants/config';
import getUserInfo from './modules/UserInfo';
import loadChildren from './modules/Children';
import loadClasses from './modules/Classes';
import loadConfiguration from './modules/Configuration';
import loadEmployees from './modules/Employees';
import loadOpenedModals from './modules/OpenedModals';
import loadPremium from './modules/Premium';
import loadSchools from './modules/Schools';
import loadTracking from './modules/Tracking';
import loadUser from './modules/User';
import loadUsers from './modules/Users';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import resetAllData from './modules/ResetAllData';
import setServices from './modules/Services';
import { getAllCypressTags, getAllCypressTagsNames, hightlightAllCypressTagsNames, hightlightAllCypressTagsNamesKey, isCypress } from '../../utils/useCypress';
import { productFruits } from 'product-fruits';
import { setIsFailed, setIsHighDemand, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setIsUserLoggouting, setReInit, setUserLoadedStatus, updateUserLoadedStatus } from '../../store/actions/loading.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useState } from '../../utils/useState';
import loadUserSettings from './modules/UserSettings';
import { hasObjectAnyValue } from 'src/utils/useFunctions';

interface Props {
  children: any
};

const UserController: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const languageData = useAppSelector((state: any) => state.language);
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);
  const services = useAppSelector((state: any) => state.services);
  const windowHandler: any = window;
  const environment = config.APP_ENVIRONMENT;
  const productFruitsCode = appEnvironment[environment].productFruitsCode;

  const defaultStatuses = useCallback(() => { 
    return {
      resetedAllData: "notStarted",
      checkToken: "notStarted",
      configuration: "notStarted",
      user: "notStarted",
      userSettings: "notStarted",
      users: "notStarted",
      employees: "notStarted",
      classes: "notStarted",
      schools: "notStarted",
      children: "notStarted",
      premium: "notStarted",
      services: "notStarted",
      openedModals: "notStarted",
      tracking: "notStarted",
    }
  }, []);

  const defaultStates = useCallback(() => {
    return {
      resetedAllData: null,
      checkToken: null,
      configuration: null,
      user: null,
      userSettings: null,
      users: null,
      employees: null,
      classes: null,
      schools: null,
      children: null,
      premium: null,
      services: null,
      openedModals: null,
      tracking: null,
    };
  }, []);

  const statuses: any = useRef(defaultStatuses());

  const states: any = useRef(defaultStates());

  const [allStatuses, setAllStatuses] = useState(defaultStatuses());
  const [allStates, setAllStates] = useState(defaultStates());

  const status: any = statuses.current;
  const state: any = states.current;

  const setStatuses = useCallback((values: any) => {
    statuses.current = values;
    setAllStatuses(values);
  }, []);

  const setStatus = useCallback((key: string, value: any) => {
    statuses.current[key] = value;
    setAllStatuses(statuses.current);
  }, []);

  const setStates = useCallback((values: any) => {
    states.current = values;
    setAllStates(values);
  }, []);

  const setState = useCallback((key: string, value: any) => {
    states.current[key] = value;
    setAllStates(states.current);
  }, []);

  useEffect(() => {
    windowHandler.isAppReady = false;
    windowHandler.isAttendanceReady = false;
    windowHandler.isCalendarReady = false;
    windowHandler.isGalleryReady = false;
    if(userData) {
      if(languageData.isFirstLoaded) {
        if(userData.isLoggedIn) {
          windowHandler.isAppReady = false;
        } else {
          setTimeout(() => {
            windowHandler.isAppReady = true;
          }, 500);
        }
      }
    }
    if(isCypress()) {
      windowHandler.getAllCypressTags = function() {
        return getAllCypressTags();
      };
      windowHandler.getAllCypressTagsNames = function() {
        return getAllCypressTagsNames();
      };
      windowHandler.hightlightAllCypressTagsNames = function() {
        return hightlightAllCypressTagsNames();
      };
      document.addEventListener('keydown', hightlightAllCypressTagsNamesKey, false);
    }
    return () => {
      windowHandler.isAppReady = false;
      windowHandler.isAttendanceReady = false;
      windowHandler.isCalendarReady = false;
      windowHandler.isGalleryReady = false;
      if(isCypress()) {
        windowHandler.getAllCypressTags = undefined;
        windowHandler.getAllCypressTagsNames = undefined;
        windowHandler.hightlightAllCypressTagsNames = undefined;
        document.removeEventListener('keydown', hightlightAllCypressTagsNamesKey, false);
      }
    } 
  }, [userData.isLoggedIn, languageData.isFirstLoaded, userData, windowHandler], [userData.isLoggedIn, languageData.isFirstLoaded]);
  

  useEffect(() => {
    if(loadingData.isLanguageLoaded && loadingData.isUserLoaded && userData.isLoggedIn) {
      const userInfo = getUserInfo(userData.userObject, location);
      if(productFruitsCode !== null && windowHandler.productFruitsIsReady) {
        productFruits.safeExec(($productFruits) => {
          $productFruits.push(['updateUserData', userInfo]);
        });
      }
    }
  }, [loadingData.isLanguageLoaded, loadingData.isUserLoaded, userData.isLoggedIn, location, userData, productFruitsCode, windowHandler.productFruitsIsReady], [location]);
  
  const handleFailedLoad = useCallback((text?: any) => {
    windowHandler.isAppReady = false;
    dispatch(setIsHighDemand(false));
    dispatch(setIsLanguageLoaded(false));
    dispatch(setIsUserLoaded(false));
    dispatch(setIsFailed(text)); 
  }, [dispatch, windowHandler]);

  const highDemand: any = useRef({});

  const initLoad = useCallback(async () => {
    let allDone = true;
    if(!loadingData.isFailed) {
      if(status.resetedAllData === "notStarted") {
        setStatus("resetedAllData", "inProcess");
        const resetedAllDataStatus = resetAllData(dispatch);
        if(resetedAllDataStatus) {
          setStatus("resetedAllData", "isDone");
          setState("resetedAllData", resetedAllDataStatus);
        } else {
          handleFailedLoad("reseted_all_data");
          setStatus("resetedAllData", "isFailed");
        }
      }
      if(status.checkToken === "notStarted") {
        setStatus("checkToken", "inProcess");
        highDemand.current["checkToken"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const checkTokenStatus = await checkToken(dispatch, userData);
        if(checkTokenStatus) {
          clearTimeout(highDemand.current["checkToken"]);
          setStatus("checkToken", "isDone");
          setState("checkToken", checkTokenStatus);
          dispatch(updateUserLoadedStatus(0));
        } else {
          clearTimeout(highDemand.current["checkToken"]);
          handleFailedLoad("check_token");
          setStatus("checkToken", "isFailed");
        }
      }
      if(status.user === "notStarted" && status.checkToken === "isDone") {
        setStatus("user", "inProcess");
        highDemand.current["user"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const userStatus = await loadUser(dispatch);
        if(userStatus) {
          clearTimeout(highDemand.current["user"]);
          setStatus("user", "isDone");
          setState("user", userStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["user"]);
          handleFailedLoad("user");
          setStatus("user", "isFailed");
        }
      }
      if(status.userSettings === "notStarted" && status.checkToken === "isDone" && status.user === "isDone") {
        setStatus("userSettings", "inProcess");
        highDemand.current["userSettings"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const userSettingsStatus = await loadUserSettings(dispatch, userData);
        if(userSettingsStatus) {
          clearTimeout(highDemand.current["userSettings"]);
          setStatus("userSettings", "isDone");
          setState("userSettings", userSettingsStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["userSettings"]);
          handleFailedLoad("user_settings");
          setStatus("userSettings", "isFailed");
        }
      }
      if(status.configuration === "notStarted" && status.checkToken === "isDone" && status.user === "isDone" && status.userSettings === "isDone") {
        setStatus("configuration", "inProcess");
        highDemand.current["configuration"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const configurationStatus = await loadConfiguration(dispatch, languageData);
        if(configurationStatus) {
          clearTimeout(highDemand.current["configuration"]);
          setStatus("configuration", "isDone");
          setState("configuration", configurationStatus);
          dispatch(updateUserLoadedStatus(20));
        } else {
          clearTimeout(highDemand.current["configuration"]);
          handleFailedLoad("configuration");
          setStatus("configuration", "isFailed");
        }
      }
      if(status.users === "notStarted" && status.checkToken === "isDone") {
        setStatus("users", "inProcess");
        highDemand.current["users"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const usersStatus = await loadUsers(dispatch, userData);
        if(usersStatus) {
          clearTimeout(highDemand.current["users"]);
          setStatus("users", "isDone");
          setState("users", usersStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["users"]);
          handleFailedLoad("users");
          setStatus("users", "isFailed");
        }
      }
      if(status.employees === "notStarted" && status.checkToken === "isDone") {
        setStatus("employees", "inProcess");
        highDemand.current["employees"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const employeesStatus = await loadEmployees(dispatch, userData);
        if(employeesStatus) {
          clearTimeout(highDemand.current["employees"]);
          setStatus("employees", "isDone");
          setState("employees", employeesStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["employees"]);
          handleFailedLoad("employees");
          setStatus("employees", "isFailed");
        }
      }
      if(status.classes === "notStarted" && status.checkToken === "isDone" && status.user === "isDone") {
        setStatus("classes", "inProcess");
        highDemand.current["classes"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const classesID = state.user ? state.user.classesID : null;
        if(classesID.length === 0) {
          clearTimeout(highDemand.current["classes"]);
          setStatus("classes", "isDone");
          setState("classes", { classes: [], classesID: []});
          dispatch(updateUserLoadedStatus(10));
        } else {
          const classesStatus = await loadClasses(dispatch, userData, classesID);
          if(classesStatus) {
            clearTimeout(highDemand.current["classes"]);
            setStatus("classes", "isDone");
            setState("classes", classesStatus);
            dispatch(updateUserLoadedStatus(10));
          } else {
            clearTimeout(highDemand.current["classes"]);
            handleFailedLoad("classes");
            setStatus("classes", "isFailed");
          }
        }
      }
      if(status.schools === "notStarted" && status.checkToken === "isDone" && status.user === "isDone") {
        setStatus("schools", "inProcess");
        highDemand.current["schools"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const schoolsID = state.user ? state.user.schoolsID : null;
        const schoolsStatus = await loadSchools(dispatch, userData, schoolsID);
        if(schoolsStatus) {
          clearTimeout(highDemand.current["schools"]);
          setStatus("schools", "isDone");
          setState("schools", schoolsStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["schools"]);
          handleFailedLoad("schools");
          setStatus("schools", "isFailed");
        }
      }
      if(status.children === "notStarted" && status.checkToken === "isDone" && status.classes === "isDone") {
        setStatus("children", "inProcess");
        highDemand.current["children"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const classes = state.classes ? state.classes.classes : [];
        const childrenStatus = await loadChildren(dispatch, userData, classes);
        if(childrenStatus) {
          clearTimeout(highDemand.current["children"]);
          setStatus("children", "isDone");
          setState("children", childrenStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["children"]);
          handleFailedLoad("children");
          setStatus("children", "isFailed");
        }
      }
      if(status.premium === "notStarted" && status.checkToken === "isDone") {
        setStatus("premium", "inProcess");
        highDemand.current["premium"] = setTimeout(() => {
          dispatch(setIsHighDemand(true));
        }, 5000);
        const premiumStatus = await loadPremium(dispatch, userData);
        if(premiumStatus) {
          clearTimeout(highDemand.current["premium"]);
          setStatus("premium", "isDone");
          setState("premium", premiumStatus);
          dispatch(updateUserLoadedStatus(10));
        } else {
          clearTimeout(highDemand.current["premium"]);
          handleFailedLoad("premium");
          setStatus("premium", "isFailed");
        }
      }
      if(status.services === "notStarted" && status.checkToken === "isDone") {
        setStatus("services", "inProcess");
        const servicesStatus = setServices(dispatch, userData);
        if(servicesStatus) {
          setStatus("services", "isDone");
          setState("services", servicesStatus);
        } else {
          handleFailedLoad("services");
          setStatus("services", "isFailed");
        }
      }
      if(status.openedModals === "notStarted" && status.checkToken === "isDone") {
        setStatus("openedModals", "inProcess");
        const openedModalsStatus = loadOpenedModals(dispatch, userData);
        if(openedModalsStatus) {
          setStatus("openedModals", "isDone");
          setState("openedModals", openedModalsStatus);
        } else {
          handleFailedLoad("opened_modals");
          setStatus("openedModals", "isFailed");
        }
      }
      if(status.tracking === "notStarted" && status.checkToken === "isDone" && status.user === "isDone") {
        setStatus("tracking", "inProcess");
        const trackingStatus = loadTracking(userData, languageData, windowHandler, location);
        if(trackingStatus) {
          setStatus("tracking", "isDone");
          setState("tracking", trackingStatus);
        } else {
          handleFailedLoad("tracking");
          setStatus("tracking", "isFailed");
        }
      }
      for(const key in status) {
        if(status[key] !== "isDone") {
          allDone = false;
        }
      }
      if(allDone) {
        setTimeout(() => {
          dispatch(setIsHighDemand(false));
          dispatch(setIsLanguageLoaded(true));
          dispatch(setIsUserLoaded(true));
          dispatch(setIsUserDataLoaded(true));
          windowHandler.isAppReady = true;
          dispatch(setUserLoadedStatus(0));
          dispatch(setReInit(false));
        }, 500);
      }
    }
  }, [dispatch, windowHandler, location, languageData, loadingData, userData, setState, setStatus, status, handleFailedLoad, state]);
  
  useEffect(() => {
    if(userData.isLoggedIn && !location.pathname.includes('auth/sso') && !location.pathname.includes('auth/switch')) {
      initLoad();
    } else {
      if(location.pathname.includes('auth/sso') || location.pathname.includes('auth/switch')) {
        dispatch(setIsHighDemand(false));
        dispatch(setIsLanguageLoaded(true));
        dispatch(setIsUserLoaded(true));
        windowHandler.isAppReady = true;
        dispatch(setUserLoadedStatus(0));
      }
      if(JSON.stringify(allStatuses) !== JSON.stringify(defaultStatuses()) && JSON.stringify(allStates) !== JSON.stringify(defaultStates())) {
        dispatch(setUserLoadedStatus(0));
        setStatuses(defaultStatuses());
        setStates(defaultStates());
      }
    }
  }, [initLoad, allStatuses, allStates, userData.isLoggedIn, defaultStates, defaultStatuses, setStates, setStatuses, dispatch, location.pathname, windowHandler], [allStatuses, allStates, userData.isLoggedIn]);

  useEffect(() => {
    if(loadingData.isUserLoggouting) {
      setTimeout(() => {
        dispatch(setIsUserLoggouting(false));
        dispatch(setIsLanguageLoaded(true));
      }, 1000);
    }
  }, [loadingData.isUserLoggouting, dispatch], [loadingData.isUserLoggouting]);

  useEffect(() => {
    if(loadingData.reInit) {
      dispatch(setIsUserLoaded(true));
      dispatch(setIsUserDataLoaded(false));
      dispatch(setUserLoadedStatus(0));
      setStatuses(defaultStatuses());
      setStates(defaultStates());
    }
  }, [loadingData.reInit, initLoad, dispatch, defaultStates, defaultStatuses, setStates, setStatuses], [loadingData.reInit]);

  useEffect(() => {
    if(!hasObjectAnyValue(services) && userData.isLoggedIn) {
      dispatch(setIsUserLoaded(true));
      dispatch(setIsUserDataLoaded(false));
      dispatch(setUserLoadedStatus(0));
      setStatuses(defaultStatuses());
      setStates(defaultStates());
    }
  }, [services, dispatch, defaultStates, defaultStatuses, setStates, setStatuses, userData.isLoggedIn], [services]);

  return (location.pathname.includes('auth/sso') || location.pathname.includes('auth/switch')) || (!userData.isLoggedIn && loadingData.isLanguageLoaded) || (loadingData.isLanguageLoaded && loadingData.isUserLoaded && userData.isLoggedIn) ? props.children : null;
}

UserController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default UserController;