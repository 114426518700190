import MainLayout from '../layouts/MainLayout';
import NotFoundContainer from '../containers/Default/404';
import PublicLayout from '../layouts/PublicLayout';
import { getAppData, setAppData } from 'src/utils/useApp';
import { getUserRole } from '../utils/useUser';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { setUrlParams } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';


const PrivateRoute = ({ children, parentChildren, teacherChildren, directorChildren }: { children?: JSX.Element, parentChildren?: JSX.Element, teacherChildren?: JSX.Element, directorChildren?: JSX.Element }) => {
  
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);
  const savedData = getAppData();
  const params = useParams();
  let newData: any = {lastUrl: location.pathname};

  useEffect(() => {
    dispatch(setUrlParams(params));
  }, [dispatch, params], [params]);
  
  if(!userData.isLoggedIn) {
    if(savedData.lastUrl !== location.pathname) {
      newData = {...newData, redirectUrl: location.pathname};
    }
    setAppData(newData);
    return (<Navigate to="/auth/login" state={{ from: location }} />);
  } else {
    setAppData(newData);
  }

  if(children) {
    return (
      <MainLayout>
        {children}
      </MainLayout>
    );
  } else if(getUserRole(userData.userObject.roleType) === "parent" && parentChildren) {
    return (
      <MainLayout>
        {parentChildren}
      </MainLayout>
    );
  } else if(getUserRole(userData.userObject.roleType) === "teacher" && teacherChildren) {
    return (
      <MainLayout>
        {teacherChildren}
      </MainLayout>
    );
  } else if(getUserRole(userData.userObject.roleType) === "director" && directorChildren) {
    return (
      <MainLayout>
        {directorChildren}
      </MainLayout>
    );
  }    
    
  return (
    <PublicLayout>
      <NotFoundContainer/>
    </PublicLayout>
  );
};

export default PrivateRoute;