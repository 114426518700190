import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import DateFormat from 'src/utils/dateFormat';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { ContextMenu } from '@firefox-devtools/react-contextmenu';
import { ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { setChildDetailModal } from 'src/store/actions/modals.actions';
import { setFilterParams } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    marginBottom: '20px',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    maxWidth: '100%',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    width: '100%',
    maxWidth: '100%',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',
    maxWidth: '100%',
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        },     
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      padding: '12px 10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      '&.classes': {
        maxWidth: '10vw',
      },
    },
  },
  child: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  },
  childImage: {
    width: '24px',
    height: '24px',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
  },
  displayName: {
    display: 'block',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '500',
  },
  classList: {
    display: 'flex',
    maxWidth: '400px',
    padding: '10px 0',
    overflow: 'auto',
    gap: '8px',
  },
  classBadge: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[300],
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  smallButton: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    '& > svg': {
      color: '#5AB8FF',
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '2px',
  },
}));

type ItemsType = {
  childrenID: any;
};

const ChildrenTable: React.FunctionComponent<ItemsType> = ({ childrenID }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const schoolSettings = userData.schoolSettings;

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getSchoolData = (schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  };

  const handleChildDetail = (childID: any) => {
    const settings = {
      isOpen: true,
      childID: childID,
    };
    dispatch(setChildDetailModal(settings));
  };

  const handleEditLink = (childID: any) => {
    const childData = getChildData(childID);
    window.open(childData.editLink); 
  };
  
  const handleClickExcuseNotes = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/excuse-notes');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickSubstitutions = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/substitutions');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickFinance = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/finance');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleClickPosts = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/timeline');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };
  
  const handleClickCalendar = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/calendar');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  childrenID.sort((a: any, b: any) => getChildData(a).surname.localeCompare(getChildData(b).surname));

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th>{t('children_nickname')}</th>
            <th>{t('children_firstname')}</th>
            <th>{t('children_surname')}</th>
            <th>{t('children_gdpr')}</th>
            <th>{t('children_birthday')}</th>
            <th>{t('children_age')}</th>
            <th>{t('children_class')}</th>
            {
              dataData.schools.length > 1 ? (
                <th>{t('children_school')}</th>
              ) : null
            }
            <th>{t('children_action')}</th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
        {
          childrenID.length > 0 ? (
            <>
              {
                childrenID.map((childID: any, key: any) => {

                  const childData = getChildData(childID);
                  const isSchoolSubstitutionsEnabled = getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions.enable : false;
                  
                  return (
                      <ContextMenuTrigger id={`child_${key}`} className={classes.tableRow} renderTag={"tr"} key={`k_${key}`} dataCy={`child${key + 1}`}>
                      <td>
                        <div onClick={() => handleChildDetail(childID)} className={classes.child}>
                          <AuthenticatedImage className={classes.childImage} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink} preferQuality="thumb"/>
                          <span className={classes.displayName}>{childData.displayName}</span>
                        </div>
                      </td>
                      <td>{childData.firstname}</td>
                      <td>{childData.surname}</td>
                      <td>{childData.hasGDPR ? t('yes') : t('no')}</td>
                      <td>{DateFormat(childData.birthday, "default", languageData, t) !== "Invalid Date" ? DateFormat(childData.birthday, "default", languageData, t) : null}</td>
                      <td>{childData.age ? childData.age : 0} {childData.age === 1 ? (t('year')).toLowerCase() : (childData.age === 2 || childData.age === 3 || childData.age === 4) ? (t('years')).toLowerCase() : (t('years_more')).toLowerCase()}</td>
                      <td className='classes'>
                        {childData.classesID.filter((classID: any) => dataData.classes.filter((classData: any) => classData.classID === classID).length !== 0).map((classID: any) => { return getClassData(classID).name; }).join(", ")}
                      </td>
                      {
                        dataData.schools.length > 1 ? (
                          <td>{htmlParse(getSchoolData(childData.schoolID).name)}</td>
                        ) : null
                      }
                      <td>
                        <div className={classes.buttons}>
                          {
                            (childData.editLink && childData.editLink !== "") ? (
                              <IconButton className={classes.smallButton} tooltip={t('edit_child')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleEditLink(childID)} dataCy='childEditButton'>
                                <SVG src="edit"/>
                              </IconButton>
                            ) : null
                          }
                          {
                            (!childData.isArchived && childData.isInAnyActiveClass) ? (
                              <IconButton className={classes.smallButton} tooltip={t('excuse_notes')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickExcuseNotes(childID)} dataCy='childExcuseNotesButton'>
                                <SVG src="calendar-excuse-notes"/>
                              </IconButton>
                            ) : null
                          }
                          {
                            (childData.attendanceProgram && childData.attendanceProgram !== null && !childData.isArchived && childData.isInAnyActiveClass && isSchoolSubstitutionsEnabled) ? (
                              <IconButton className={classes.smallButton} tooltip={t('substitutions')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickSubstitutions(childID)} dataCy='childSubstitutionsButton'>
                                <SVG src="calendar-substitutions"/>
                              </IconButton>
                            ) : null
                          }
                          {
                            (getUserRole(userData.userObject.roleType) === "parent" && childData.payment && childData.payment.totalCount > 0) ? (
                              <IconButton className={classes.smallButton} tooltip={t('finance')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickFinance(childID)} dataCy='childFinanceButton'>
                                <SVG src="finance"/>
                              </IconButton>
                            ) : null 
                          }
                          <IconButton className={classes.smallButton} tooltip={t('posts')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickPosts(childID)} dataCy='childTimelineButton'>
                            <SVG src="timeline"/>
                          </IconButton>
                          {
                            (!childData.isArchived && childData.isInAnyActiveClass) ? (
                              <IconButton className={classes.smallButton} tooltip={t('calendar')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickCalendar(childID)} dataCy='childCalendarButton'>
                                <SVG src="calendar"/>
                              </IconButton>
                            ) : null
                          }
                        </div>
                      </td>
                    </ContextMenuTrigger>
                  );
                })
              }
            </>
          ) : (
            <tr className={classes.tableRow}>
              <td colSpan={9}>
                {t('no_children_found')}
              </td>
            </tr>
          )
        }       
        </tbody> 
      </table>
      {
        childrenID.map((childID: any, key: any) => {

          const childData = getChildData(childID);
          const isSchoolSubstitutionsEnabled = getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions.enable : false;
          
          return (
            <ContextMenu id={`child_${key}`} key={`k_${key}`} preventHideOnScroll={false} hideOnLeave={true}>
              <ContextMenuItem onClick={() => handleChildDetail(childID)}>
                <SVG src="info-circle-outlined"/>
                {t('detail_child')}
              </ContextMenuItem>
              {
                (childData.editLink && childData.editLink !== "") ? (
                  <ContextMenuItem onClick={() => handleEditLink(childID)}>
                    <SVG src="pencil"/>
                    {t('edit_child')}
                  </ContextMenuItem>
                ) : null
              }
              {
                (!childData.isArchived && childData.isInAnyActiveClass) ? (
                  <ContextMenuItem onClick={() => handleClickExcuseNotes(childID)}>
                    <SVG src="calendar-excuse-notes"/>
                    {t('excuse_notes')}
                  </ContextMenuItem>
                ) : null
              }
              {
                (childData.attendanceProgram && childData.attendanceProgram !== null && !childData.isArchived && childData.isInAnyActiveClass && isSchoolSubstitutionsEnabled) ? (
                  <ContextMenuItem onClick={() => handleClickSubstitutions(childID)}>
                    <SVG src="calendar-substitutions"/>
                    {t('substitutions')}
                  </ContextMenuItem>
                ) : null
              }
              {
                (getUserRole(userData.userObject.roleType) === "parent" && childData.payment && childData.payment.totalCount > 0) ? (
                  <ContextMenuItem onClick={() => handleClickFinance(childID)}>
                    <SVG src="finance"/>
                    {t('finance')}
                    <small>({childData.payment.totalCountUnpaid !== 0 ? (<em>{childData.payment.totalCountUnpaid}</em>) : null})</small>
                  </ContextMenuItem>
                ) : null 
              }
              <ContextMenuItem onClick={() => handleClickPosts(childID)}>
                <SVG src="timeline"/>
                {t('posts')}
              </ContextMenuItem>
              {
                (!childData.isArchived && childData.isInAnyActiveClass) ? (
                  <ContextMenuItem onClick={() => handleClickCalendar(childID)}>
                    <SVG src="calendar"/>
                    {t('calendar')}
                  </ContextMenuItem>
                ) : null
              }
            </ContextMenu>
          );
        })
      }
    </div>
  );
}

export default ChildrenTable;