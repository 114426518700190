import * as MainService from '../../../services/main.service';
import * as UserService from '../../../services/user.service';
import CheckIcon from '@mui/icons-material/Check';
import CloseButton from 'src/components/Buttons/CloseButton';
import Flag from 'react-world-flags';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { languages } from '../../../constants/languages';
import { setAppData } from 'src/utils/useApp';
import { setConfiguration } from '../../../store/actions/configuration.actions';
import { setIsFailed, setIsLanguageChanging, setIsLanguageLoaded } from '../../../store/actions/loading.actions';
import { setLanguage } from '../../../store/actions/language.actions';
import { setMembership } from '../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { setLanguageModal } from 'src/store/actions/modals.actions';
import { saveUserSettings } from 'src/utils/useUser';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
  
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
  },
  search: {
    backgroundColor: theme.colors.white,
    padding: '24px',
    width: 'calc(100% - 48px)',
    position: "relative",
    '& input': {
      borderRadius: "10px",
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      padding: "10px 15px 10px 45px",
      width: "calc(100% - 60px)",
      fontSize: "0.75rem",
      color: theme.colors.grey[700],
      '&:focus': {
        outline: "none",
        borderColor: theme.colors.primaryBlue[500],
      }
    },
    '& svg': {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%) scale(0.8) !important",
      left: "34px",
      width: "24px",
      color: theme.colors.grey[700],
    }
  },
  noresults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      maxWidth: '80%',
    },
  },
  langauges: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: "0 24px 24px 24px",
    width: 'calc(100% - 48px)',
  },
  button: {
    '& > span': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  },
  isCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryBlue[500],
    minWidth: '18px',
    minHeight: '18px',
    width: '18px',
    height: '18px',
    maxWidth: '18px',
    maxHeight: '18px',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.colors.primaryBlue[500],
    marginRight: '0px',
    marginLeft: 'auto',
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.white,
      transform: 'scale(0.6)'
    },
  },
  isNoCheck: {
    display: 'block',
    backgroundColor: theme.colors.white,
    minWidth: '18px',
    minHeight: '18px',
    width: '18px',
    height: '18px',
    maxWidth: '18px',
    maxHeight: '18px',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    marginRight: '0px',
    marginLeft: 'auto',
    borderRadius: '100%',
  },
}));

const LanguageModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  
  const [state, setState] = useStates({
    languagesList: languages,
    search: '',
    canChange: true,
  });
  
  useEffect(() => {
    const tempLanguages = state.languagesList.map((item: any) => {
      return {...item, isSearch: true};
    });
    setState("languagesList", tempLanguages);
  }, [setState, state.languagesList], []);  
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setLanguageModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleChangeLan = async (e: any, language: any) => {
    e.stopPropagation();
    e.preventDefault();
    setState("canChange", false);
    dispatch(setIsLanguageChanging(true));
    dispatch(setLanguage(language));  
    if(userData.isLoggedIn) {
      const resultLan = await saveUserSettings(dispatch, userData, "customizations", ["language"], language);
      if(resultLan) {
        MainService && MainService.getConfiguration(language.toLowerCase()).then((result: any) => {
          if(result) {
            const configurationData = result.data;
            UserService && UserService.getUserData(language.toLowerCase()).then((result: any) => {
              const userMembershipContent = result.data.user.membership;
              setAppData({language: language});
              dispatch(setConfiguration(configurationData));
              dispatch(setMembership(userMembershipContent));
              setState("canChange", true);
            }).catch(() => {
              dispatch(setIsLanguageLoaded(false));
              dispatch(setIsLanguageChanging(false));
              dispatch(setIsFailed("dictionaries")); 
              setState("canChange", true); 
            });
          }
        }).catch(() => {
          dispatch(setIsLanguageLoaded(false));
          dispatch(setIsLanguageChanging(false));
          dispatch(setIsFailed("dictionaries"));  
          setState("canChange", true);
        });
      } else {
        dispatch(setIsLanguageLoaded(false));
        dispatch(setIsLanguageChanging(false));
        dispatch(setIsFailed("dictionaries"));  
        setState("canChange", true);
      }
    } else {
      setAppData({language: language});
      setState("canChange", true);  
    }   
  };
  
  const handleSearchChange = (e: any) => {
    setState("search", e.target.value.toLowerCase());
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "languageModal" : null}>
        <div className={classes.header}>
          <p>{t('select_language')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.search}>                                                                                                                                                                                                                 
            <input placeholder={t('search') + "..."} onKeyDown={handleSearchChange} onChange={handleSearchChange} onKeyUp={handleSearchChange}/>
            <SVG src="search"/>
          </div>
          <div className={classes.langauges}>
            {
              state.languagesList.map((language: any, key: any) => language.lan.toLowerCase().includes(state.search) || language.nativeLan.toLowerCase().includes(state.search) ? (
                <NormalButton
                  className={classes.button}
                  buttonType="secondary"
                  onClick={(e: any) => handleChangeLan(e, language.acronym)}
                  key={`k_${key}`}
                  startIcon={language.acronym === (languageData.language || 'EN') ? (<span className={classes.isCheck}><CheckIcon/></span>) : (<span className={classes.isNoCheck}></span>)}
                  disabled={language.acronym === (languageData.language || 'EN') || !state.canChange}
                  dataCy={`profileLanguageMenu${language.acronym}Button`}
                >
                  <Flag code={(language.flag || 'us')} height="16" />
                  {language.acronym === (languageData.language || 'EN') ? language.nativeLan : language.lan}
                </NormalButton>
              ) : null)
            }
            {
              state.languagesList.filter((language: any) => language.lan.toLowerCase().includes(state.search) || language.nativeLan.toLowerCase().includes(state.search)).length === 0 ? (
                <div className={classes.noresults}>
                  <img src="/resources/images/noresults.png" alt='no_results'/>
                  {(t('no_results_found') + '...')}
                </div>
              ): null
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LanguageModal;