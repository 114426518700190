import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import ChatBottomBar from '../ChatBottomBar';
import config from 'src/constants/config';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Loading from '../Loading';
import Messages from '../Messages';
import moment from 'src/utils/moment';
import React, { useCallback, useRef } from 'react';
import Sidebar from '../Sidebar';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import { base64ToArrayBuffer, getFileType, isKey, isValidMessage } from 'src/utils/useFunctions';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { isMobile } from 'react-device-detect';
import { isNotificationSupported, onMessageListener } from 'src/utils/useFirebase';
import { setCommunicationChangeThreadNameModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import {
  addToCommunicationList,
  setCommunicationNotificationsCount,
  setCommunicationThreadID,
  setCommunicationViewMode,
  updateThreadData,
} from 'src/store/actions/communication.actions';

interface Props {
  isSidebarOpen?: any;
  isSearchOpen?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%',
    gap: '1px',
    backgroundColor: theme.colors.grey[325],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 calc(75% - 0.5px)',
    gap: '1px',
    backgroundColor: theme.colors.white,
    maxHeight: '100%',
    maxWidth: '100%',
    width: (props: Props) => {
      if(props.isSidebarOpen) return '';
      else return '';
    },
  },
  topBar: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: (props: Props) => {
      if(props.isSearchOpen) return '114px';
      else return '57px';
    },
    flex: (props: Props) => {
      if(props.isSearchOpen) return '0 0 114px';
      else return '0 0 57px';
    },
  },
  row: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[325],
    height: '56px',
    flex: '0 0 56px',
    width: 'calc(100% - 16px)',
    padding: '0 8px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
      display: 'block',
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& > button': {
        fontSize: '14px',
        fontWeight: '500',
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'inherit',
        textTransform: 'unset',
      },
      '& > span': {
        padding: '6px 8px',
      },
    },
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      '&:hover': {
        backgroundColor: theme.colors.grey[125],
      },
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
  },
  searchCloseButton: {
    textTransform: 'unset',
    backgroundColor: theme.colors.grey[200],
    color: theme.colors.black,
    fontSize: '14px',
    borderRadius: '6px',
    height: '38px',
  },
  buttons: {
    display: 'flex',
    gap: '8px',
    marginLeft: 'auto',
    '& > button': {
      width: '36px',
      height: '36px',
      '&:not(:last-of-type)': {
        backgroundColor: theme.colors.grey[150],
        color: theme.colors.primaryBlue[500],
        '&:hover': {
          backgroundColor: theme.colors.grey[125],
        },
        '&:disabled': {
          color: theme.colors.grey[500],
        },
      },
      '&:last-of-type': {
        backgroundColor: (props: Props) => {
          if(props.isSidebarOpen) return theme.colors.primaryBlue[500];
          else return theme.colors.grey[150];
        },
        color: (props: Props) => {
          if(props.isSidebarOpen) return theme.colors.white;
          else return theme.colors.primaryBlue[500];
        },
        '&:hover': {
          backgroundColor: (props: Props) => {
            if(props.isSidebarOpen) return theme.colors.primaryBlue[600];
            else return theme.colors.grey[125];
          },
        },
      },
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
  },
  smallAvatars: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '48px',
    height: '48px',
    '& > div': {
      transform: 'scale(0.8)',
    },
  },
  bigAvatars: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '96px',
    '& > div': {
      transform: 'scale(2)',
    },
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  singleGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  smallGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      '&:first-of-type': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:last-of-type': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  mediumGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '26px',
      height: '26px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        top: 'calc(100% - 26px)',
        left: '11px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 20px)',
        left: '0px',
      },
      '&:nth-child(3)': {
        bottom: 'calc(100% - 20px)',
        right: '0px',
      },
    },
  },
  largeGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(4)': {
        top: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  bigGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
    },
    '& > span': {
      position: 'absolute',
      top: 'calc(100% - 23.5px)',
      right: '0.5px',
      width: '23px',
      height: '23px',
      backgroundColor: theme.colors.grey[325],
      color: theme.colors.black,
      borderRadius: '6px',
      fontSize: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  avatar: {
    position: 'relative',
    width: '46px',
    height: '46px',
    borderRadius: '12px',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: 'inherit',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
    },
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '8px',
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
    },
  },
  loadingSmall: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    transform: 'translate(-50%, -8px)',
    backgroundColor: theme.colors.white,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& > div': {
      transform: 'scale(0.5)',
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type ThreadType = {
  threadID: any;
  isLoading: any;
  isLoadingMessages: any;
  isFirstTimeLoaded: any;
  setIsLoading: any;
  setIsLoadingMessages: any;
  setIsFirstTimeLoaded: any;
};

const Thread: React.FunctionComponent<ThreadType> = ({ threadID, isLoading, isLoadingMessages, isFirstTimeLoaded, setIsLoading, setIsLoadingMessages, setIsFirstTimeLoaded }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const isSidebarAutoOpen = layout === "desktop" ? getUserSetting(userData.userSettings, "addons", ["communication", "communication_autoopen_sidebar"]) : false;
  const unreadThreads = communicationData.notificationsCount;
  const drafts = communicationData.drafts;

  const messagesContainerRef: any = useRef(null);
  const messageContainerHeight = messagesContainerRef.current ? messagesContainerRef.current.clientHeight : 0;

  const [state, setState] = useStates({
    isProcesing: false,
    messages: [],
    lastDateTime: moment(),
    isSidebarOpen: isSidebarAutoOpen,
    isSearchOpen: false,
    search: "",
    editMessageID: null,
    media: [],
    message: "",
    reset: Symbol(),
    limit: 0,
  });
  
  const chatInputRef: any = useRef(null);
  const searchInputRef: any = useRef(null);
  const messagesRef = useRef(state.messages);
  const lastDateTimeRef = useRef(state.lastDateTime);
  const refMedia = useRef(state.media);
  const isUnreadRef = useRef(false);

  const limit = state.editMessageID ? state.limit : Math.ceil(messageContainerHeight / 31);
  const limitRef = useRef(limit);

  const classes = useStyles({
    isSidebarOpen: state.isSidebarOpen,
    isSearchOpen: state.isSearchOpen,
  });

  const handleNavigate = useCallback((loc: any) => {
    if(customViewMode) {
      dispatch(setCommunicationThreadID(null));
      dispatch(setCommunicationViewMode(loc));
    } else {
      navigate(`/communication/${loc}`);
    }
  }, [customViewMode, navigate, dispatch]);

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadData = useCallback((threadID: any) => {
    return communicationData.list.filter((item: any) => item.threadID === threadID).length === 0 ? null : communicationData.list.find((item: any) => item.threadID === threadID);
  }, [communicationData.list]);

  const threadData = getThreadData(threadID);
  const isThreadReady = threadData && threadData.length !== 0;
  const users = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const allUsers = isThreadReady ? threadData.users.filter((item: any) => getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const allUsersData = useMemo(() => isThreadReady ? threadData.users : [], [isThreadReady, threadData]);
  const isThreadAuthor = isThreadReady ? userData.userObject.userID === threadData.authorID : false;
  const isThreadArchived = isThreadReady ? threadData.archived : false;
  const dataUsers = allUsers.length === 1 ? allUsers : users;

  const getGroupClass = (count: any) => {
    if(count === 1) return classes.singleGroup;
    else if(count === 2) return classes.smallGroup;
    else if(count === 3) return classes.mediumGroup;
    else if(count === 4) return classes.largeGroup;
    else return classes.bigGroup;
  };

  const getThreadName = () => {
    if((threadData.name && threadData.name.length !== 0)) {
      return threadData.name;
    } else {
      return getUserData(dataUsers[0]).displayName;
    }
  };

  const getThreadRealName = () => {
    if((threadData.name && threadData.name.length !== 0)) {
      return threadData.name;
    } else {
      return '';
    }
  };

  const handleToggleSidebar = () => {
    setState("isSidebarOpen", !state.isSidebarOpen);
  };

  const getMessages = (messages: any) => {
    let newMessages: any = [];
    if(Array.isArray(newMessages)) {
      newMessages = messages.map((item: any) => {
        if(item.media && item.media.length > 0) {
          const medias = item.media.filter((media: any) => media.thumbLink && media.fullsizeLink);
          return {...item, media: medias};
        } else {
          return item;
        }
      }).filter((item: any) => item.thread && item.thread.threadID === threadID && (item.text.length > 0 || item.media.length > 0));
    }
    return newMessages;
  };

  const messages = getMessages(messagesRef.current);
  const reset = state.reset;
  
  const handleSetMessages = useCallback((value: any) => {
    setState("messages", value);
    messagesRef.current = value;
  }, [setState]);

  const handleGetNewMessages = useCallback((auto?: any) => {
    const isScrollBottom = messagesContainerRef.current.scrollTop === messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
    if(state.isSearchOpen || state.search) return;
    setIsLoadingMessages((auto && typeof auto === "boolean") ? false : true);
    const settings = {
      threadID: threadID,
      dateTimeFrom: lastDateTimeRef.current.format("YYYY-MM-DD HH:mm:ss"),
    };
    communicationService && communicationService.listMessages(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          if(response.length > 0) {
            const originalData = messagesRef.current;
            const newMessageData = response;
            const dataMap = new Map();
            originalData.forEach((item: any) => dataMap.set(item.messageID, item));
            newMessageData.forEach((item: any) => dataMap.set(item.messageID, item));
            const mergedData = Array.from(dataMap.values());
            handleSetMessages(mergedData);
            messagesRef.current = mergedData;
            const now = moment();
            setState("lastDateTime", now);
            lastDateTimeRef.current = now;
            setIsLoadingMessages(false);
            if(messagesContainerRef && messagesContainerRef.current && isScrollBottom) {
              setTimeout(() => {
                if(messagesContainerRef && messagesContainerRef.current && isScrollBottom) {
                  messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
                }
              } , 1);
            }
            const newThreadData = newMessageData[0].thread;
            const newData = {
              threadID: threadID,
              data: newThreadData,
            };
            dispatch(updateThreadData(newData));
          } else {
            setIsLoadingMessages(false);
          }
        } else {
          createNotification(t("community_messages_failed_load"), "error");
          setIsLoadingMessages(false);
        }
      } else {
        createNotification(t("community_messages_failed_load"), "error");
        setIsLoadingMessages(false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_load"), "error");
      setIsLoadingMessages(false);
    });
  }, [communicationService, setIsLoadingMessages, handleSetMessages, setState, t, threadID, dispatch, state.isSearchOpen, state.search]);

  const handleGetNewThread = useCallback(() => {
    const settings = {
      threadID: threadID,
    };
    if(isNaN(threadID)) return;
    communicationService && communicationService.listThreads(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          if(response.length === 1) {
            const newThreadData = response[0];
            const newData = {
              threadID: threadID,
              data: newThreadData,
            };
            dispatch(updateThreadData(newData));
          } else {
            handleNavigate("threads");
            createNotification(t("community_thread_not_found"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_load"), "error");
        }
      } else {
        createNotification(t("community_thread_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("community_thread_failed_load"), "error");
    });
  }, [communicationService, dispatch, handleNavigate, t, threadID]);

  const loadThread = useCallback(() => {
    handleSetMessages([]);
    setState("isSearchOpen", false);
    const isThreadExist = communicationData.list.filter((item: any) => item.threadID === threadID).length !== 0;
    if(isThreadExist) {
      setIsLoading(false);
      if(!isFirstTimeLoaded) {
        setIsFirstTimeLoaded(true);
      }
    } else {
      const settings = {
        threadID: threadID,
      };
      if(isNaN(threadID)) return;
      communicationService && communicationService.listThreads(settings).then((result: any) => {
        if(result) {
          if(result.data) {
            const response = result.data[config.JSONLD_DATA];
            if(response.length === 1) {
              const currentThread = response[0];
              dispatch(addToCommunicationList(currentThread)); 
              setIsLoading(false);
              if(!isFirstTimeLoaded) {
                setIsFirstTimeLoaded(true);
              }
            } else {
              setIsLoading(false);
              handleNavigate("threads");
              createNotification(t("community_thread_not_found"), "error");
            }
          } else {
            setIsLoading(false);
            createNotification(t("community_thread_failed_load"), "error");
          }
        } else {
          setIsLoading(false);
          createNotification(t("community_thread_failed_load"), "error");
        }
      }).catch(() => {
        setIsLoading(false);
        createNotification(t("community_thread_failed_load"), "error");
      });
    }
  }, [communicationData.list, communicationService, dispatch, handleNavigate, handleSetMessages, t, threadID, setState, setIsLoading, isFirstTimeLoaded, setIsFirstTimeLoaded]);

  const handleSearchChange = (name: any, value: any, e: any) => {
    setState(name, value.toLowerCase());
  };

  const handleToggleSearch = () => {
    if(state.isSearchOpen && searchInputRef && searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    setState("search", "");
    setState("isSearchOpen", !state.isSearchOpen);
    if(layout === "mobile" && !state.isSearchOpen) {
      handleToggleSidebar();
    }
  };

  const handleSearchMessages = useCallback((value: any) => {
    setIsLoadingMessages(true);
    handleSetMessages([]);
    const settings = {
      threadID: threadID,
      limit: limitRef.current,
      search: value,
    };
    communicationService && communicationService.listMessages(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          handleSetMessages(response);
          setIsLoadingMessages(false);
          if(messagesContainerRef && messagesContainerRef.current) {
            setTimeout(() => {
              if(messagesContainerRef && messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
              }
            } , 1);
          }
        } else {
          createNotification(t("community_messages_failed_load"), "error");
          setIsLoadingMessages(false);
        }
      } else {
        createNotification(t("community_messages_failed_load"), "error");
        setIsLoadingMessages(false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_load"), "error");
      setIsLoadingMessages(false);
    });
  }, [communicationService, setIsLoadingMessages, handleSetMessages, t, threadID]);

  const handleGetMessage = useCallback((messageID: any, tryCount: any) => {
    const isScrollBottom = messagesContainerRef.current.scrollTop === messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
    const settings = {
      messageID: messageID,
    };
    communicationService && communicationService.listMessages(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          const newMessage = response[0];
          let newMessages;
          if(messagesRef.current.filter((item: any) => item.messageID === newMessage.messageID).length === 0) {
            newMessages = [...messagesRef.current, newMessage];
          } else {
            newMessages = messagesRef.current.map((item: any) => {
              if(item.messageID === newMessage.messageID) {
                return {...item, ...newMessage};
              } else {
                return item;
              }
            });
          }
          handleSetMessages(newMessages);
          if(newMessage.media.filter((item: any) => item.uploadedDate).length !== newMessage.media.length && tryCount < 5) {
            setTimeout(() => {
              const newTry = tryCount + 1;
              handleGetMessage(newMessage.messageID, newTry);
            }, 1000);
          }
          if(messagesContainerRef && messagesContainerRef.current && isScrollBottom) {
            setTimeout(() => {
              if(messagesContainerRef && messagesContainerRef.current && isScrollBottom) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
              }
            } , 1);
          }
        } else {
          createNotification(t("community_message_failed_load"), "error");
        }
      } else {
        createNotification(t("community_message_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("community_message_failed_load"), "error");
    });
  }, [communicationService, handleSetMessages, t]);

  const handleOnSubmit = (data: any) => {
    if(data.value.length > 0) {
      const isMessageValid = isValidMessage(data.value, t);
      if(isMessageValid !== true) {
        createNotification(isMessageValid, "error");
        return;
      }
    }
    if(state.editMessageID) {
      handleEdit(data);
    } else {
      handleCreate(data);
    }
  };

  const handleCreate = (data: any) => {
    setState("isProcesing", true);
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const settings = {
      threadID: threadID,
      text: data.value,
      mediaToUpload: newMedias,
    };
    communicationService && communicationService.createMessage(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data;
          const responseMedia = result.data.mediaToUpload;
          if(responseMedia.length > 0) {
            const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
              return {...file, uploadUrl: responseMedia.find((theFile: any) => theFile.id === key).uploadUrl};  
            })
            handleUploadMedias(response, uploadMedias);
          } else {
            handleFinish(response, false);
          }
        } else {
          createNotification(t("community_messages_failed_create"), "error");
          setState("isProcesing", false);
        }
      } else {
        createNotification(t("community_messages_failed_create"), "error");
        setState("isProcesing", false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_create"), "error");
      setState("isProcesing", false);
    });
  };

  const handleEdit = (data: any) => {
    setState("isProcesing", true);
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const deletedMedias = state.media.filter((fileData: any) => fileData.status === "deleted").map((fileData: any) => {
      return fileData.mediaID;
    });
    const settings = {
      text: data.value,
      mediaToUpload: newMedias,
      mediaToDeleteID: deletedMedias,
    };
    communicationService && communicationService.updateMessage(state.editMessageID, settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data;
          const responseMedia = result.data.mediaToUpload;
          if(responseMedia.length > 0) {
            const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
              return {...file, uploadUrl: responseMedia.find((theFile: any) => theFile.id === key).uploadUrl};  
            })
            handleUploadMedias(response, uploadMedias);
          } else {
            handleFinish(response, false);
          }
        } else {
          createNotification(t("community_messages_failed_create"), "error");
          setState("isProcesing", false);
        }
      } else {
        createNotification(t("community_messages_failed_create"), "error");
        setState("isProcesing", false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_create"), "error");
      setState("isProcesing", false);
    });
  };

  const handleUploadMedias = (response: any, sentFiles: any) => {
    const file = sentFiles[0];
    const newFiles = sentFiles.filter((fileData: any) => fileData.uid !== file.uid);
    const updateFiles = refMedia.current.map((fileData: any) => {
      if(fileData.uid === file.uid) {
        return {...fileData, status: "uploading"};
      } else {
        return fileData;
      }
    });
    setMedia(updateFiles);
    const reader = new FileReader();
    reader.onload = function() {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(',')[1];
      const arrayBuffer = base64ToArrayBuffer(base64);
      communicationService && communicationService.uploadFile(file.uploadUrl.replace("http://", "https://"), arrayBuffer).then((result: any) => {
        if(result) {
          if(result.status === 201) {
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.uid === file.uid) {
                return {...fileData, status: "uploaded"};
              } else {
                return fileData;
              }
            });
            setMedia(updateFiles);
            if(newFiles.length === 0) {
              handleFinish(response, true); 
            } else {
              handleUploadMedias(response, newFiles);  
            }
          } else {
            createNotification(state.editMessageID ? t("community_messages_failed_edit") : t("community_messages_failed_create"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setMedia(updateFiles);
            setState("isProcesing", false);
          }
        } else {
          createNotification(state.editMessageID ? t("community_messages_failed_edit") : t("community_messages_failed_create"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setMedia(updateFiles);
          setState("isProcesing", false);
        }
      }).catch((e: any) => {
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setMedia(updateFiles);
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : (state.editMessageID ? t('community_messages_failed_edit') : t("community_messages_failed_create")), "error");
        setState("isProcesing", false);
      });
    };
    reader.readAsDataURL(file.blob);
  };

  const handleFinish = (newMessage: any, isMedia: boolean) => {
    let newMessages;
    if(messagesRef.current.filter((item: any) => item.messageID === newMessage.messageID).length === 0) {
      newMessages = [...messagesRef.current, newMessage];
    } else {
      newMessages = messagesRef.current.map((item: any) => {
        if(item.messageID === newMessage.messageID) {
          return {...item, ...newMessage};
        } else {
          return item;
        }
      });
    }
    handleSetMessages(newMessages);
    const newThreadData = {
      threadID: threadID,
      data: newMessage.thread,
    };
    dispatch(updateThreadData(newThreadData));
    setState("isProcesing", false);
    setState("editMessageID", null);
    setMedia([]);
    setMessage("");
    if(messagesContainerRef && messagesContainerRef.current) {
      setTimeout(() => {
        if(messagesContainerRef && messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
        }
      } , 1);
    }
    if(isMedia) {
      if(newMessage.media.filter((item: any) => item.uploadedDate).length !== newMessage.media.length) {
        handleGetMessage(newMessage.messageID, 0);
      }
    }
  };

  const handleAddMessage = (value: any) => {
    const newMessages = [...messages, value];
    handleSetMessages(newMessages);
  };

  const handleChangeThreadName = () => {
    const settings = {
      isOpen: true,
      threadID: threadID,
      threadName: getThreadRealName(),
      onChange: handleAddMessage,
    };
    dispatch(setCommunicationChangeThreadNameModal(settings));
  };

  const getIsUnread = useCallback(() => {
    if(threadData) {
      const lastMessageDate = threadData.lastMessage ? threadData.lastMessage.createdDate : moment();
      const lastMessageAuthor = threadData.lastMessage ? threadData.lastMessage.authorID : moment();
      const lastSeenDate = allUsersData.filter((item: any) => item.userID === userData.userObject.userID).length === 1 ? allUsersData.find((item: any) => item.userID === userData.userObject.userID).lastSeenDate : null;
      const result = lastSeenDate ? (lastMessageAuthor === userData.userObject.userID ? false : moment(lastSeenDate).isBefore(moment(lastMessageDate))) : true;
      return result;
    } else {
      return false;
    }
  }, [allUsersData, threadData, userData.userObject.userID]);

  const setMessage = useCallback((message: any) => {
    setState("message", message);
  }, [setState]);

  useEffect(() => {
    loadThread();
    if(chatInputRef.current && !isMobile) {
      const isDraftExist = drafts.filter((item: any) => item.threadID === threadID).length === 1;
      const currentMessage = isDraftExist ? drafts.find((item: any) => item.threadID === threadID).value : "";
      setMessage(currentMessage);
      chatInputRef.current.value = currentMessage;
    }
    if(chatInputRef.current && !isMobile) {
      chatInputRef.current.focus();
    }
  }, [loadThread, setMessage, drafts, threadID], [threadID]);

  useEffect(() => {
    if(state.search && state.search.length !== 0) {
      handleSearchMessages(state.search);
    } else {
      handleSetMessages([]);
      setState("reset", Symbol());
      if(messagesContainerRef && messagesContainerRef.current) {
        setTimeout(() => {
          if(messagesContainerRef && messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
          }
        } , 1);
      }
    }
  }, [state.search, handleSearchMessages, setState, handleSetMessages], [state.search]);

  const handleSetLastDateTime = (value: any) => {
    setState("lastDateTime", value);
  };

  const handleSetEditMessageID = (value: any) => {
    const isScrollBottom = messagesContainerRef.current.scrollTop === messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
    if(typeof value === "number") {
      const getText = messages.filter((item: any) => item.messageID === value).length === 0 ? null : messages.find((item: any) => item.messageID === value).text;
      const getMedia = messages.filter((item: any) => item.messageID === value).length === 0 ? null : messages.find((item: any) => item.messageID === value).media;
      if(getText || getMedia) {
        setMessage(getText);
        const newMedia = getMedia.map((file: any) => {
          return {
            ...file,
            type: file.type === "image" ? "image/jpg" : file.type === "attachment" ? "application/pdf" : file.type === "video" ? "video/mp4" : "",
            status: "hosted",
            uid: file.mediaID,
          };
        });
        setMedia(newMedia);
        setState("editMessageID", value);
        if(chatInputRef && chatInputRef.current) {
          setTimeout(() => {
            chatInputRef.current.focus();
            setTimeout(function(){ chatInputRef.current.selectionStart = chatInputRef.current.selectionEnd = 10000; }, 0);
          }, 1);
        }
        if(messagesContainerRef && messagesContainerRef.current && isScrollBottom) {
          setTimeout(() => {
            if(messagesContainerRef && messagesContainerRef.current) {
              messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
            }
          } , 1);
        }
      } else {
        setState("editMessageID", null);
      }
    } else {
      setState("editMessageID", null);
    }
  };

  const handleUpdateThread = () => {
    handleGetNewMessages();
    handleGetNewThread();
  };

  const handleBackToThreads = () => {
    handleNavigate("threads");
  };

  const setMedia = (media: any) => {
    setState("media", media);
    refMedia.current = media;
  };

  const autoRefresh = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_messages"]);

  useEffect(() => {
    if(autoRefresh > 0) {
      let getMessagesInterval;
      getMessagesInterval = setInterval(() => {
        handleGetNewMessages(true);
      }, autoRefresh);
      return () => {
        clearInterval(getMessagesInterval);
      };
    }
  }, [autoRefresh, handleGetNewMessages], [threadID]);

  useEffect(() => {
    if(limit > 0) {
      limitRef.current = limit;
    }
  }, [limit, setState], [reset, limit]);

  useEffect(() => {
    if(getIsUnread() && isUnreadRef.current === false) {
      isUnreadRef.current = true;
      const newCount = unreadThreads - 1;
      dispatch(setCommunicationNotificationsCount(newCount));
      setTimeout(() => {
        isUnreadRef.current = false;
      }, 3000);
    }
  }, [getIsUnread, dispatch, unreadThreads], [threadID, unreadThreads]);
 
  useEffect(() => {
    if(messages.length > 0) {
      handleGetNewMessages(true);
    }
  }, [handleGetNewMessages, messages], [threadData]);

  if(userData.isLoggedIn && isNotificationSupported) {
    onMessageListener().then((payload: any) => {
      if(payload.data && payload.data.type && payload.data.type === "chat") {
        if(payload.data.threadID) {
          const notificationThreadID = parseInt(payload.data.threadID);
          if(notificationThreadID === threadID) {
            handleUpdateThread();
          }
        }
      }
    });
  }
  
  return isFirstTimeLoaded ? (
    <>
    {
      isThreadReady ? ( 
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.topBar}>
              <div className={classes.row}>
                {
                  layout === "mobile" ? (
                    <IconButton onClick={handleBackToThreads} tooltip={t('communication_threads')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                      <SVG src="arrow-left"/>
                    </IconButton>
                  ) : null
                }
                <div className={classes.smallAvatars}>
                  <div className={classes.avatars}>
                    <div className={getGroupClass(dataUsers.length)}>
                      {
                        dataUsers.slice(0, dataUsers.length > 4 ? 3 : 4).map((item: any, key: any) => {
                          const userData = getUserData(item);
                          return userData ? (
                            <AuthenticatedImage className={classes.avatar} thumbLink={userData.photo.thumbLink} key={`k_${key}`}/>
                          ) : null;
                        })
                      }
                      {
                        dataUsers.length > 4 ? (
                          <span>
                            +{dataUsers.length - 3}
                          </span>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
                <span>
                  {
                    (isThreadAuthor && !isThreadArchived) ? (
                      <Button onClick={handleChangeThreadName}>
                        {getThreadName()}
                      </Button>
                    ) : (
                      <span>
                        {getThreadName()}
                      </span>
                    )
                  }
                </span>
                <div className={classes.buttons}>
                  <IconButton onClick={handleUpdateThread} disabled={isLoading || isLoadingMessages} tooltip={t('communication_thread_reload')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                    <SVG src="reload"/>
                  </IconButton>
                  <IconButton onClick={handleToggleSidebar} tooltip={t('communication_thread_info')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                    <SVG src="more"/>
                  </IconButton>
                </div>
              </div>
              {
                state.isSearchOpen ? (
                  <div className={classes.row}>
                    <Input name="search" placeholder={t('communication_thread_search')} prepend={<SVG src="search"/>} onInputEnd={handleSearchChange} customRefInput={searchInputRef} autoFocus={true} disabled={isLoadingMessages} inputDelay={1500}/>
                    <Button className={classes.searchCloseButton} onClick={handleToggleSearch}>{t('close')}</Button>
                  </div>
                ) : null
              }
            </div>
            {
              isThreadReady ? (
                <Messages threadID={threadID} messages={messages} setMessages={handleSetMessages} reset={reset} search={state.search} limit={limit} setLastDateTime={handleSetLastDateTime} isLoadingMessages={isLoadingMessages} setIsLoadingMessages={setIsLoadingMessages} editMessageID={state.editMessageID} setEditMessageID={handleSetEditMessageID} messagesRef={messagesRef} messagesContainerRef={messagesContainerRef}/>
              ) : null
            }
            <ChatBottomBar currentThreadID={threadID} message={state.message} setMessage={setMessage} messages={messages} media={state.media} setMedia={setMedia} onSubmit={handleOnSubmit} editMessageID={state.editMessageID} setEditMessageID={handleSetEditMessageID} disabled={isThreadArchived || state.isProcesing} chatInputRef={chatInputRef}/>      
          </div>       
          {
            state.isSidebarOpen ? (
              <Sidebar threadID={threadID} messages={messages} setMessages={handleSetMessages} onSearchToggle={handleToggleSearch} onSidebarToggle={handleToggleSidebar}/>
            ) : null
          }
        </div>
      ) : (
        <Loading/>
      )
    }
    </>
  ) : (
    <Loading/>
  );
};

export default Thread;