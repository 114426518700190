import moment from 'src/utils/moment';
import { setAttendanceDate, setAttendanceSelectedChildren } from 'src/store/actions/attendance.actions';
import { setIsAutoLoggedOff } from 'src/store/actions/user.actions';
import { setIsFailed, setIsLanguageLoaded, setIsUserLoaded } from 'src/store/actions/loading.actions';
import { setIsFiltersVisible, setIsSearchVisible } from 'src/store/actions/layout.actions';
import { setTimelinePosts } from 'src/store/actions/timeline.actions';

const resetAllData = (dispatch: any) => {
    dispatch(setAttendanceDate(moment()));
    dispatch(setAttendanceSelectedChildren([]));
    dispatch(setIsLanguageLoaded(false));
    dispatch(setIsUserLoaded(false));
    dispatch(setIsFailed(false)); 
    dispatch(setIsAutoLoggedOff(false));
    dispatch(setIsFiltersVisible(false));
    dispatch(setIsSearchVisible(false));
    dispatch(setIsFiltersVisible(false));
    dispatch(setTimelinePosts([]));
    return true;
};

export default resetAllData;