import * as MainService from 'src/services/main.service';
import htmlParse from 'html-react-parser';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  aboutGDPRPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: 'calc(100% - 112px)',
    overflow: 'auto',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },       
  },
  title: {
    color: '#00A2FF',
    fontSize: '48px',
    fontWeight: '500',
    lineHeight: '48px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '54px',
      lineHeight: '54px',
    }, 
    [theme.breakpoints.down('md')]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '32px',
    },  
  },
  subtitle: {
    color: '#003065',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '24px',
    marginTop: '16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '40px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '24px',
    }, 
    '& > a': {
      color: '#00A2FF',
      '&:hover': {
        color: '#013164',
      },
    }, 
  },
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
    clipPath: 'ellipse(60% 90% at 50% 100%)',
    backgroundColor: '#00A2FF',
    marginTop: 'auto',  
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const userData = useAppSelector((state: any) => state.user);
  
  const [state, setState] = useStates({
    gdprData: userData.isLoggedIn ? configurationData.configuration.informationPages.gdpr : {},
    isReady: Object.keys(userData.isLoggedIn ? configurationData.configuration.informationPages.gdpr : {}).length !== 0,
  });

  useEffect(() => {
    if(!state.isReady) {
      MainService && MainService.getConfiguration().then((result: any) => {
        if(result) {
          const gdprData = result.data.informationPages.gdpr;
          setState("gdprData", gdprData);
          setState("isReady", true);
        }
      });
    }
  }, [state.isReady, setState], [state.isReady]);
  
  return (
    <div className={classes.aboutGDPRPage}>
      <div className={classes.wrapper}>
        {
          state.isReady ? (
            <>
              <h1 className={classes.title}>{(state.gdprData.title)}</h1>
              <h2 className={classes.subtitle}>{htmlParse(state.gdprData.content)}</h2>
            </>
          ) : null
        }
      </div>
      <div className={classes.block}/>
    </div>
  );
};

export default PageTemplate;