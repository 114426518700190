import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ChildInfoItem from '../../Items/ChildInfoItem';
import Clamp from 'react-multiline-clamp';
import CloseButton from 'src/components/Buttons/CloseButton';
import htmlParse from 'html-react-parser';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React, { useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import Switch from '../../Forms/Switch';
import theme from '../../../ui/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Chart } from 'react-google-charts';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setTimelinePollDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  size: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  buttons: {
    display: 'flex',
    gap: '4px',
  },
  body: {
    padding: '24px',
    maxHeight: 'calc(60vh - 40px)',
    overflow: 'auto',
  },
  info: {
    display: "flex",
    flexDirection: "column",
    '& span': {
      fontWeight: "600",
      fontSize: "16",
    },
    '& p': {
      fontSize: "14px",
    }
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
  },
  answers: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    marginTop: '12px',
  },
  cardContainer: {
    width: '100%',
  },
  cardWrapper: {
    boxShadow: theme.shadows[0],
    backgroundColor: theme.colors.grey[125],
    color: '#5A5C7B',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    '&:first-of-type': {
      borderTopLeftRadius: '12px', 
      borderTopRightRadius: '12px',    
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '12px', 
      borderBottomRightRadius: '12px',    
    },
    '&.Mui-disabled': {
      backgroundColor: theme.colors.grey[125],
    },
  },
  card: {
    padding: '10px',
    maxWidth: '100%',
    '& > .MuiAccordionSummary-content': {
      padding: '0',
      margin: '0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > span': {
        color: theme.colors.grey[700],
        fontSize: '18px',
        fontWeight: '600',
        marginLeft: '12px',
        maxWidth: 'calc(100% - 52px)',
        flex: '1 1 calc(100% - 52px)',
        wordBreak: 'break-all',  
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '10px',
      backgroundColor: theme.colors.white,
      width: '32px',
      height: '32px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[350],
      borderRadius: '100%',
    },  
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
    '&.Mui-disabled': {
      opacity: '1',
    },
    '&.active': {
      '& > .MuiAccordionSummary-expandIconWrapper': {
        color: theme.colors.primaryBlue[500],
      },    
    },  
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0px',
    flex: '0 0 40px',
    justifyContent: 'center',
    '& > p': {
      fontSize: '14px',
      color: '#C8CBDF',
    },
  },
  childrenList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    padding: '0px 18px 13px',
    alignItems: 'center',
    width: 'calc(100% - 36px)',
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(50vh - 70px)',
    height: 'fit-content',
  },
  childWrapper: {
    width: (props: Props) => (props.size + 30) + 'px',
    flex: (props: Props) => '0 0 ' + (props.size + 30) + 'px',  
  },
  chartWrapper: {
    width: '100%',
  },
}));

const TimelinePollDetailModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const uniqueID = useAppSelector((state: any) => state.modals).timelinePollDetailModal.uniqueID;
  const postData = timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
  const dispatch = useAppDispatch();
  const answerCountRef = useRef(0);

  const [state, setState] = useStates({
    isLoaded: false,
    childrenData: [],
    chartData: [
      ["Answer", "Number of children"],
    ],
    answersCount: 0,
    isShowChart: false,
  });

  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const sizes = {
    xs: 32,
    sm: 38,
    md: 40,
    lg: 48,
    xl: 54,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? sizes.xl : isLg ? sizes.lg : isMd ? sizes.md : isSm ? sizes.sm : isXs ? sizes.xs : 30;

  const classes = useStyles({
    size: size,
  });
  
  useEffect(() => {
    setState("isLoaded", false);
    if(postData.children.length > 0) {
      const newChildren = postData.children.map((item: any) => {
        if(dataData.children.filter((theChild: any) => theChild.childID === item.childID).length === 1) {
          const childData = dataData.children.find((theChild: any) => theChild.childID === item.childID);
          return {...childData, postSeen: item.postSeen ? item.postSeen : false, pollAnswers: item.pollAnswers ? item.pollAnswers : []};
        } else {
          return null;
        }
      }).filter((item: any) => item !== null);
      setState("childrenData", newChildren);
      let tempChartData = state.chartData;
      postData.poll.answers.forEach((answer: any) => {
        const newAnswer = [
          answer.answer,
          postData.children.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).length
        ];
        const newAnswersCount = answerCountRef.current + postData.children.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).length;
        setState("answersCount", newAnswersCount);
        answerCountRef.current = newAnswersCount;
        tempChartData = [...tempChartData, newAnswer];
      });
      const newAnswer: any = [
        t("not_answered"),
        newChildren.filter((child: any) => child.pollAnswers.length === 0).length
      ];
      tempChartData = [...tempChartData, newAnswer];
      setState("chartData", tempChartData);
      setState("isLoaded", true);
    }      
  }, [uniqueID, dataData.children, postData.children, postData.poll.answers, setState, state.chartData, state.childrenData, t], [uniqueID]);
  
  const onClose = ()  => {
    const settings = {
      isOpen: false,
      uniqueID: null,
    };
    dispatch(setTimelinePollDetailModal(settings));   
  };
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleShowChart = () => {
    setState("isShowChart", !state.isShowChart);
  };

  const chartOptions = {
    pieHole: 0.4,
    is3D: false,
    legend: { position: smallDevice ? 'top' : 'right', alignment: 'start', maxLines: 100 },
  };

  return state.isLoaded ? (
    <Modal
      open={true} 
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "pollDetailModal" : null}>
        <div className={classes.header}>
          <p>{t('survey_results')}</p>
          <div className={classes.buttons}>
            {
              state.answersCount > 0 ? (
                <Switch checked={state.isShowChart} onChange={handleShowChart} label={t('show_chart')}/>
              ) : null
            }
            <CloseButton onClick={handleClose} dataCy="timesButton"/>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.info}>
            <span>{postData.title}</span>
            <Clamp lines={4}>
              <p>{htmlParse(postData.description)}</p>
            </Clamp>
          </div>
          {
            state.isShowChart ? (
              <div className={classes.chartWrapper}>
              {
                state.chartData.length > 0 ? (
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="300px"
                    data={state.chartData}
                    options={chartOptions}
                  />
                ) : null
              }
              </div>
            ) : null
          }
          <div className={classes.answers}>
            {
              postData.poll.answers.map((answer: any, key: any) => (
                <div className={classes.cardContainer} key={`k_${key}`}>
                  <Accordion className={classes.cardWrapper} disabled={postData.children.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).length === 0 ? true : false}>
                    <AccordionSummary className={`${classes.card} ${'active'}`} expandIcon={postData.children.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).length === 0 ? (<SVG src="minus"/>) : (<SVG src="chevron-down"/>)}>
                      <Clamp lines={1}>
                        <span>
                          {answer.answer}
                        </span>
                      </Clamp>
                      <div className={classes.status}>
                        <p>({postData.children.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).length}{answer.limit !== 0 ? '/' + answer.limit : null})</p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.childrenList}>
                      {
                        state.childrenData.filter((child: any) => child.pollAnswers.indexOf(answer.id) !== -1).map((child: any, key: any) => (
                          <div className={classes.childWrapper} key={`k_${key}`}>
                            <ChildInfoItem childID={child.childID} size={size} mode='detail' useModal='true' />
                          </div>
                        ))
                      }
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            }
            <div className={classes.cardContainer}>
              <Accordion className={classes.cardWrapper} disabled={postData.children.filter((child: any) => child.pollAnswers.length === 0).length === 0 ? true : false}>
                <AccordionSummary className={classes.card} expandIcon={postData.children.filter((child: any) => child.pollAnswers.length === 0).length === 0 ? (<SVG src="minus"/>) : (<SVG src="chevron-down"/>)}>
                  <Clamp lines={1}>
                    <span>
                      {t("not_answered")}
                    </span>
                  </Clamp>
                  <div className={classes.status}>
                    <p>({postData.children.filter((child: any) => child.pollAnswers.length === 0).length})</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.childrenList}>
                  {
                    state.childrenData.filter((child: any) => child.pollAnswers.length === 0).map((child: any, key: any) => (
                      <div className={classes.childWrapper} key={`k_${key}`}>
                        <ChildInfoItem childID={child.childID} size={size} mode='notanswered' useModal={true} />
                      </div>
                    ))
                  }
                </AccordionDetails>
              </Accordion>          
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType={"primary"} onClick={handleClose} dataCy="closeButton">
            {t("close")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null
};

export default TimelinePollDetailModal;