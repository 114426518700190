import AuthLayout from '../layouts/AuthLayout';
import { getUserHomepage } from '../utils/useUser';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { setAppData } from 'src/utils/useApp';
import { setUrlParams } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';

const AuthRoute = ({ children, isAuthPage }: { children: JSX.Element, isAuthPage?: boolean }) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const params = useParams();
  const newData: any = {lastUrl: location.pathname};
  setAppData(newData);

  useEffect(() => {
    dispatch(setUrlParams(params));
  }, [dispatch, params], [params]);  

  return (userData.isLoggedIn && !userData.isLogging && isAuthPage) ? (
    <Navigate to={getUserHomepage(dispatch, dataData, layoutData, userData)} state={{ from: location }} />
  ) : (
    <AuthLayout>
      {children}
    </AuthLayout>
  );
};

export default AuthRoute;