import config from 'src/constants/config';
import HttpService from './http.service';

const getGitLabEnvironments = () => {
  const gitlabUrl = 'https://gitlab.com/';
  const projectID = '29899425';
  const privateToken = config.GITLAB_KEY;
  return HttpService.getRoot(gitlabUrl, `api/v4/projects/${projectID}/environments?states=available`, {'PRIVATE-TOKEN': privateToken}, {});
};

export {
  getGitLabEnvironments,
};