import * as Sentry from '@sentry/react';
import appEnvironment from 'src/constants/environment';
import Banner from 'src/components/Layouts/Banner';
import config from 'src/constants/config';
import CookiesBanner from 'src/components/Layouts/CookiesBanner';
import getUserInfo from '../UserController/modules/UserInfo';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import reportWebVitals from 'src/utils/reportWebVitals';
import semver from 'semver';
import SetupScreen from 'src/components/Screen/SetupScreen';
import StorageService from 'src/services/storage.service';
import useMousePosition from 'src/utils/useMousePosition';
import WelcomeScreen from 'src/components/Screen/WelcomeScreen';
import { clarity } from 'clarity-js';
import { createUseStyles } from 'react-jss';
import { getAppData, removeAppData, setAppData } from 'src/utils/useApp';
import { getUserRole, getUserSetting, getUserSettingCategory } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setIsAutoOpenWelcomeScreen, setIsOpenSetupScreen, setIsOpenWelcomeScreen } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  children: any;
};

interface ClassesProps {
  mousePosition: any;
};

const useStyles = createUseStyles((theme: any) => ({
  versionBanner: {
    position: 'fixed',
    left: '16px',
    bottom: '48px',
    zIndex: theme.zIndex.banner,
    width: '400px',
    [theme.breakpoints.down('md')]: {
      bottom: '64px',
      width: 'calc(100% - 72px)',
    }, 
  },
  cypressTag: {
    position: 'fixed',
    top: (props: ClassesProps) => (props.mousePosition.y ? props.mousePosition.y : '0') + 'px',
    left: (props: ClassesProps) => (props.mousePosition.x ? props.mousePosition.x : '0') + 'px',
    transformOrigin: 'center right',
    zIndex: theme.zIndex.cypressTag,
    fontSize: '11px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    margin: '0',
    color: theme.colors.black,
    backgroundColor: theme.colors.grey[500],
    padding: '10px 5px',
    textAlign: 'right',
    pointerEvents: 'none',
    '&:empty': {
      display: 'none',
    },
  }, 
}));

const LayoutController: React.FunctionComponent<Props> = (props:Props) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const layoutData = useAppSelector((state: any) => state.layout);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);

  const environment = config.APP_ENVIRONMENT;
  const version = config.APP_VERSION;
  const sentryUrl = appEnvironment[environment].sentryUrl;
  const clarityID = appEnvironment[environment].clarityID;
  const gaIDs = appEnvironment[environment].gaID;

  const savedData = useMemo(() => getAppData(), []);
  const cookies = useMemo(() => savedData.cookies ? savedData.cookies : [], [savedData]);
  const cookiesSettings = layoutData.cookies;
  const lastVersion = savedData.version ? savedData.version : config.APP_VERSION;
  const isNewVersion = semver.lt(lastVersion, config.APP_VERSION);

  const mousePosition = useMousePosition();

  const [state, setState] = useStates({
    isNewVersionBannerVisible: true,
    isUserSettingsLoaded: false,
  });

  const classes = useStyles({
    mousePosition: mousePosition,
  });

  const sendToAnalytics = useCallback(({ id, name, value }: any) => {
    const gaTrackers = gaIDs.map((_: any, key: any) => {
      return 't' + key;
    });
    ReactGA.event({
      category: 'Web Vitals',
      action: name,
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      label: id,
      nonInteraction: true,
    }, gaTrackers);
  }, [gaIDs]);

  const initAnalytics = useCallback(() => {
    if(sentryUrl !== null && cookies.includes("analytics")) {
      Sentry.init({
        dsn: sentryUrl,
        environment: environment,
        release: version,
        tracesSampleRate: 1.0,
        initialScope: {
          tags: { version: version },
        },
        ignoreErrors: [
          't.replace is not a function'
        ],
        denyUrls: [
          "clarity.js",
          /extensions\//i,
          /^chrome:\/\//i,
          /^chrome-extension:\/\//i,
        ],
        beforeBreadcrumb(breadcrumb: any) {
          if (breadcrumb.level && ['fatal', 'critical', 'error'].includes(breadcrumb.level)) {
            return breadcrumb;
          } else {
            return null;
          }
        },
      });
    }
    if(clarityID !== null && cookies.includes("analytics")) {
      clarity.consent();
      clarity.start({
        projectId: clarityID,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
        cookies: ['clarityUserID', 'clarityUserEmail'],
      });
      const userInfo = getUserInfo(userData.userObject, location);
      const appVersion = (userInfo.props && userInfo.props.version) ? userInfo.props.version.toString() : "";
      const appApiServer = (userInfo.props && userInfo.props.apiServer) ? userInfo.props.apiServer.toString() : "";
      const appEnvironment = (userInfo.props && userInfo.props.environment) ? userInfo.props.environment.toString() : "";
      const appBranch = (userInfo.props && userInfo.props.branch) ? userInfo.props.branch.toString() : "";
      if(userData.isLoggedIn) {
        const userType = "logged".toString();
        const userEmail = userInfo.email ? userInfo.email.toString().toLowerCase() : "";
        const userID = userInfo.username ? userInfo.username.toString() : "";
        const userRole = userInfo.role ? userInfo.role.toString() : "";
        const isPremiumParent = ((userInfo.props && userInfo.props.premiumParent) ? true : false).toString();
        const isPremiumSchool = ((userInfo.props && userInfo.props.premiumSchool) ? true : false).toString();
        clarity.identify(userEmail, undefined, undefined, userID);
        StorageService.setCookie('userID', userID, 1);
        StorageService.setCookie('userEmail', userEmail, 1);
        StorageService.setCookie('userType', userType, 1);
        StorageService.setCookie('userRole', userRole, 1);
        if(getUserRole(userData.userObject.roleType) === "parent") {
          StorageService.setCookie('isPremiumParent', isPremiumParent, 1);
        }
        StorageService.setCookie('isPremiumSchool', isPremiumSchool, 1);
        StorageService.setCookie('version', appVersion, 1);
        if(userInfo.props.environment !== "production") {
          StorageService.setCookie('apiServer', appApiServer, 1); 
          StorageService.setCookie('environment', appEnvironment, 1); 
        }
        if(userInfo.props.environment === "review") {
          StorageService.setCookie('branch', appBranch, 1); 
        }
        clarity.set('userID', userID);
        clarity.set('userEmail', userEmail);
        clarity.set('userType', userType);
        clarity.set('userRole', userRole);
        if(getUserRole(userData.userObject.roleType) === "parent") {
          clarity.set('isPremiumParent', isPremiumParent);
        }
        clarity.set('isPremiumSchool', isPremiumSchool);
        clarity.set('version', appVersion);
        if(userInfo.props.environment !== "production") {
          clarity.set('apiServer', appApiServer);
          clarity.set('environment', appEnvironment);
        }
        if(userInfo.props.environment === "review") {
          clarity.set('branch', appBranch);
        }
        setTimeout(() => {
          StorageService.deleteCookie('userID');
          StorageService.deleteCookie('userEmail');
          StorageService.deleteCookie('userType');
          StorageService.deleteCookie('userRole');
          if(getUserRole(userData.userObject.roleType) === "parent") {
            StorageService.deleteCookie('isPremiumParent');
          }
          StorageService.deleteCookie('isPremiumSchool');
          StorageService.deleteCookie('version');
          if(userInfo.props.environment !== "production") {
            StorageService.deleteCookie('apiServer');
            StorageService.deleteCookie('environment');
          }
          if(userInfo.props.environment === "review") {
            StorageService.deleteCookie('branch');
          }
        }, 3000);
      } else {
        const userType = "unlogged".toString();
        clarity.identify(userType, undefined, undefined);
        StorageService.setCookie('userType', userType, 1);
        StorageService.setCookie('version', appVersion, 1);
        if(userInfo.props.environment !== "production") {
          StorageService.setCookie('apiServer', appApiServer, 1); 
          StorageService.setCookie('environment', appEnvironment, 1); 
        }
        if(userInfo.props.environment === "review") {
          StorageService.setCookie('branch', appBranch, 1); 
        }
        clarity.set('userType', userType);
        clarity.set('version', appVersion);
        if(userInfo.props.environment !== "production") {
          clarity.set('apiServer', appApiServer);
          clarity.set('environment', appEnvironment);
        }
        if(userInfo.props.environment === "review") {
          clarity.set('branch', appBranch);
        }
        setTimeout(() => {
          StorageService.deleteCookie('userType');
          StorageService.deleteCookie('version');
          if(userInfo.props.environment !== "production") {
            StorageService.deleteCookie('apiServer');
            StorageService.deleteCookie('environment');
          }
          if(userInfo.props.environment === "review") {
            StorageService.deleteCookie('branch');
          }
        }, 3000);
      }
    }
    if(gaIDs !== null && cookies.includes("analytics")) {
      const gaData = gaIDs.map((gaID: any, key: any) => {
        return {
          trackingId: gaID,
          gaOptions: {
            name: 't' + key,
          },
        };
      });
      ReactGA.initialize(gaData);
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
      reportWebVitals(sendToAnalytics);
    } else {
      if(config.APP_SHOW_METRICS_IN_CONSOLE) {
        reportWebVitals(console.log);
      }
    }
  }, [clarityID, cookies, environment, gaIDs, sendToAnalytics, sentryUrl, version, userData.isLoggedIn, location, userData.userObject]);

  const handleSetupScreen = useCallback(() => {
    dispatch(setIsOpenSetupScreen(true));
  }, [dispatch]);

  const handleWelcomeScreen = useCallback(() => {
    dispatch(setIsAutoOpenWelcomeScreen(true));
    dispatch(setIsOpenWelcomeScreen(true));
  }, [dispatch]);

  useEffect(() => {
    if(isNewVersion) {
      setTimeout(function() {
        setAppData({version: config.APP_VERSION});
      }, 5000);
    }
    if(savedData.version === undefined) {
      setAppData({version: config.APP_VERSION});
    }
    if(savedData.cookies === undefined) {
      setAppData({cookies: []});
      StorageService.deleteAllCookies();
    }
    if(savedData.classbook !== undefined) {
      removeAppData("classbook");
    }
    if(savedData.theme !== undefined) {
      removeAppData("theme");
    }
    if(savedData.users === undefined) {
      setAppData({users: []});
    }
    if(savedData.addons !== undefined) {
      removeAppData("addons");
    }
    if(savedData.notifications === undefined) {
      setAppData({notifications: config.APP_NOTIFICATIONS});
    }
    if(savedData.twigchat === undefined) {
      setAppData({twigchat: { drafts: [] }});
    }
  }, [isNewVersion, savedData, dispatch], []);

  useEffect(() => {
    if(state.isUserSettingsLoaded) {
      const tempSavedData = getAppData();
      const setupData = getUserSettingCategory(userData.userSettings, "setup");
      const isSetupScreen = (setupData && setupData.value) ? setupData.value.filter((item: any) => item.value === null).length !== 0 : false;
      const isWelcomeScreen = getUserSetting(userData.userSettings, "addons", ["app", "app_update_welcome_screen"]);
      if(isSetupScreen) {
        setTimeout(() => {
          handleSetupScreen();
        }, 1000);
      }
      if((tempSavedData.changelogVersion === undefined || tempSavedData.changelogVersion !== config.APP_CHANGELOG_VERSION) && userData.isLoggedIn && isWelcomeScreen && config.APP_CHANGELOG_ROLES.includes(getUserRole(userData.userObject.roleType))) {
        setTimeout(() => {
          handleWelcomeScreen();
          setState("isNewVersionBannerVisible", false);
        }, 1000);
      }
    }
  }, [handleSetupScreen, handleWelcomeScreen, userData.isLoggedIn, savedData.changelogVersion, userData.userSettings, userData.userObject.roleType, state.isUserSettingsLoaded, setState], [state.isUserSettingsLoaded, savedData.changelogVersion, userData.isLoggedIn, userData.userSettings]);

  useEffect(() => {
    if(!state.isUserSettingsLoaded) {
      if(userData.userSettings.length > 0) {
        setState("isUserSettingsLoaded", true);
      }
    }
  }, [userData.userSettings, state.isUserSettingsLoaded, setState], [userData.userSettings])

  useEffect(() => {
    initAnalytics();
  }, [initAnalytics], [cookies, userData.userObject]);
  
 return (
    <>
      {
        isCypress() ? (
          <div className={classes.cypressTag}>
            {layoutData.cypressCurrentTag ? layoutData.cypressCurrentTag : ''}
          </div>
        ) : null
      }
      {
        (isNewVersion && state.isNewVersionBannerVisible) ? (
          <Banner className={classes.versionBanner} icon={true} button={(savedData.addons !== undefined && savedData.addons.changelog_autoopen !== true) ? (<span onClick={handleWelcomeScreen}>{t('open_changelog')}</span>) : null} closeable={true} type="info">
            {t("new_version_info", {lastVersion: '<b>' + savedData.version + '</b>', newVersion: '<b>' + config.APP_VERSION + '</b>'})}
          </Banner>
        ) : null
      }
      {
        ((cookiesSettings.length === 0 || (Array.isArray(cookiesSettings) && cookiesSettings.indexOf("necessary") === -1)) && !modalsData.cookiesModal.isOpen && !isCypress('cookies')) ? (
          <CookiesBanner/>
        ) : null
      }
      {
        (layoutData.isOpenWelcomeScreen && !layoutData.isOpenSetupScreen && userData.isLoggedIn) ? (
          <WelcomeScreen/>
        ) : null
      }
      {
        (layoutData.isOpenSetupScreen && userData.isLoggedIn) ? (
          <SetupScreen/>
        ) : null
      }
      {props.children}
    </>
  );
};

LayoutController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default LayoutController;