import MuiIconButton from '@mui/material/IconButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { isCypress } from '../../../utils/useCypress';
import { Tooltip } from '@mui/material';

interface Props {
  maxWidth: any;
  iconColor: any;
  size: any;
  isLabelClickable: any;
};

const useStyles = createUseStyles((theme: any) => ({
  iconButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    '& > label': {
      fontSize: '80%',
      cursor: (props: Props) => {
        if(props.isLabelClickable) return 'pointer';
        else return 'default';
      },
      pointerEvents: (props: Props) => {
        if(!props.isLabelClickable) return 'none';
        else return '';
      },
    },
  },
  iconButton: {
    color: (props: Props) => props.iconColor,
    width: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    minWidth: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    maxWidth: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    height: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    minHeight: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    maxHeight: (props: Props) => {
      if(props.size === "small") return '26px';
      else if(props.size === "medium") return '32px';
      else if(props.size === "large") return '40px';
      else if(props.size === "xl") return '48px';
      else if(props.size === "xxl") return '56px';
      else if(props.size === "xxxl") return '64px';
      else return '';
    },
    '& > span': {
      display: 'flex',
    },
    '& > svg': {
      width: (props: Props) => {
        if(props.size === "small" || props.size === "medium" || props.size === "large" || props.size === "xl" || props.size === "xxl" || props.size === "xxxl") return '75%';
        else return '';
      },
      height: (props: Props) => {
        if(props.size === "small" || props.size === "medium" || props.size === "large" || props.size === "xl" || props.size === "xxl" || props.size === "xxxl") return '75%';
        else return '';
      },
    },
  },
  tooltipWidth: {
    maxWidth: (props: Props) => props.maxWidth + 'px',
  },
}));

type IconButtonType = {
  id?: any;
  label?: any;
  isLabelClickable?: any;
  className?: any;
  onClick?: any;
  iconColor?: any;
  tooltip?: any;
  tooltipPosition?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  tooltipArrow?: boolean;
  tooltipMaxWidth?: any;
  children?: any;
  dataCy?: string;
  disabled?: boolean;
  disableRipple?: boolean;
  tabIndex?: any;
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl';
};

const IconButton: React.FunctionComponent<IconButtonType> = ({id, label, isLabelClickable, className, onClick, iconColor, tooltip, tooltipPosition = "right", tooltipArrow = true, tooltipMaxWidth = '100', children, dataCy, disabled, disableRipple, tabIndex = -1, size}) => {
  
  const buttonID = id ? id : getElementID();

  const classes = useStyles({
    maxWidth: tooltipMaxWidth,
    iconColor: iconColor,
    size: size,
    isLabelClickable: isLabelClickable,
  });

  return (
    <>
      {
        (tooltip && !disabled) ? (
          <Tooltip title={tooltip} classes={{ tooltip: classes.tooltipWidth }} placement={tooltipPosition} arrow={tooltipArrow}>
            {
              label ? (
                <div className={classes.iconButtonWrapper}>
                  <MuiIconButton id={buttonID} type="button" className={`${classes.iconButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} disableRipple={disableRipple} data-cy={isCypress() ? dataCy : null}>
                    {children}
                  </MuiIconButton>
                  <label htmlFor={buttonID}>
                    {label}
                  </label>
                </div>
              ) : (
                <MuiIconButton id={buttonID} type="button" className={`${classes.iconButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} disableRipple={disableRipple} data-cy={isCypress() ? dataCy : null}>
                  {children}
                </MuiIconButton>
              )
            }
          </Tooltip>
        ) : (
          <>
            {
              label ? (
                <div className={classes.iconButtonWrapper}>
                  <MuiIconButton id={buttonID} type="button" className={`${classes.iconButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} disableRipple={disableRipple} data-cy={isCypress() ? dataCy : null}>
                    {children}
                  </MuiIconButton>
                  <label htmlFor={buttonID}>
                    {label}
                  </label>
                </div>
              ) : (
                <MuiIconButton id={buttonID} type="button" className={`${classes.iconButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} disableRipple={disableRipple} data-cy={isCypress() ? dataCy : null}>
                  {children}
                </MuiIconButton>
              )
            }
          </>
        )
      }
    </>
  );
};

export default IconButton;