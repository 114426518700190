import * as AuthService from '../../../services/auth.service';
import Banner from '../../../components/Layouts/Banner';
import config from '../../../constants/config';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import httpResult from 'src/constants/httpresult';
import i18next from 'i18next';
import Input from '../../../components/Forms/Input';
import NormalButton from '../../../components/Buttons/NormalButton';
import React, { useCallback, useMemo } from 'react';
import Select from 'src/components/Forms/Select';
import SHA1 from '../../../services/crypto.service';
import SVG from '../../../components/Images/SvgRenderer';
import { convertToCapitalFirstLetter, handleDetectCamera, isExistInArrayWithObject, onlyUnique, parseEnviromentName } from 'src/utils/useFunctions';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppApiServer, getAppData, getAppEnvironmentApiData, getCurrentApiServer, setAppAccessToken, setAppData } from 'src/utils/useApp';
import { getGitLabEnvironments } from 'src/services/gitlab.service';
import { isCypress } from '../../../utils/useCypress';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ObjectType } from 'src/types/types';
import { resetAttachments, resetImages, resetVideos } from 'src/store/actions/medias.actions';
import { setIsAutoLoggedOff, setIsLoggedIn, setIsLogging, setUserObject } from '../../../store/actions/user.actions';
import { setIsFailed, setIsLanguageLoaded } from '../../../store/actions/loading.actions';
import { setSSOLoginQrCodeModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  loginPage: {
    marginTop: '55px',
    marginBottom: '55px',
  },
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  loginCard: {
    maxWidth: 'calc(400px - 96px)',
    width: '100%',
    padding: '24px 48px 32px 48px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 96px)',
      marginBottom: '24px',
      padding: '16px 24px 24px 24px',
      '& + div': {
        marginLeft: '0px',
      },
    },
  },
  loginCardAvatarWrapper: {
    textAlign: 'center',
    marginTop: '16px',
  },
  loginCardAvatar: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: theme.colors.grey[250],
    borderRadius: '100%',
    '& + div': {
      marginLeft: '10px',
    },
    '& svg': {
      width: '50px',
      height: '50px',
    },
  },
  loginCardTitle: {
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: theme.colors.black,
  },
  loginCardForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center', 
  },
  loginCardDownload: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginCardSelectWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    marginTop: '24px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      width: '100%',
      fontSize: '14px',
      '& svg': {
        width: '20px',
        height: '20px',
        marginRight: '5px',
      },
    },
  },
  spinner: {
    display: 'flex',
    marginRight: '5px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  loginHereButton: {
    margin: '1rem auto',
  }, 
  banner: {
    marginTop: '16px',
  },
  select: {
    width: '100%',
    flex: 'unset',
  },
}));

const Login: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: any) => state.user);
  const savedData = getAppData();
  const newData = useMemo(() => { return {...savedData, redirectUrl: null}; }, [savedData]);
  const windowHandler: any = window;
  const apiServers: ObjectType = config.API_SERVERS;
  const savedUsers = savedData.users ? savedData.users : [];
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  const apiServersList: any = Object.keys(apiServers).map((item: any) => {
    return {name: apiServers[item].name, url: apiServers[item].url};
  });

  const [state, setState] = useStates({
    isLoading: false,
    isAutoLoggedOff: userData.isAutoLoggedOff,
    redirectUrl: savedData ? ((savedData.redirectUrl === null || savedData.redirectUrl === undefined) ? null : ((savedData.redirectUrl === "/auth/logout" || savedData.redirectUrl === "/premium/status") ? null : savedData.redirectUrl)) : null,
    password: null,
    email: null,
    currentApiServer: savedData ? getCurrentApiServer(apiServersList,savedData.apiServer) : getCurrentApiServer(apiServersList, null),
    environments: [],
    isCameraAvailable: false,
  });

  useEffect(() => {
    dispatch(setIsAutoLoggedOff(false));
    setAppData(newData);
  }, [dispatch, newData], []);

  useEffect(() => {
    handleDetectCamera(function(hasWebcam: any) {
      if(hasWebcam) {
        setState("isCameraAvailable", true); 
      }
    });  
  }, [setState], []);
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(state.email && state.password) {
      const encrypt = SHA1(state.password);
      setState("isLoading", true);
      AuthService && AuthService.signIn(state.email, encrypt).then((result) => {
        if(result.data.authToken && result.data.user) {
          const authToken = result.data.authToken;
          const userData = result.data.user;
          setAppAccessToken(authToken);
          const savedUsers = savedData.users ? savedData.users : [];
          const userDataToStorage = {
            userID: userData.userID,
            email: userData.email,
            firstname: userData.firstname,
            surname: userData.surname,
            roleType: userData.roleType,
            photo: userData.photo.thumbLink,
          };
          if(!isExistInArrayWithObject(savedUsers, useApiServers ? ["email", "apiServer"] : "email", useApiServers ? [userData.email, state.currentApiServer.url] : userData.email)) {
            const userDataToSave: any = {
              userID: userData.userID,
              email: userData.email,
              firstname: userData.firstname,
              surname: userData.surname,
              roleType: userData.roleType,
              photo: userData.photo.thumbLink,
              accessToken: authToken,
            };
            if(useApiServers) {
              userDataToSave.apiServer = state.currentApiServer.url;
            }
            const newSavedUsers = [...savedUsers, userDataToSave];
            setAppData({user: userDataToStorage, users: newSavedUsers});
          } else {
            const newUsersData = savedUsers.map((item: any) => {
              if(item.userID === userData.userID) {
                return {...item, accessToken: authToken};
              } else {
                return item;
              }
            });
            setAppData({user: userDataToStorage, users: newUsersData});
          }
          dispatch(resetAttachments());
          dispatch(resetImages());
          dispatch(resetVideos());
          dispatch(setIsLogging(true));
          dispatch(setIsLanguageLoaded(true));
          dispatch(setIsFailed(false));
          dispatch(setIsLoggedIn(true));
          dispatch(setUserObject(userData));
          windowHandler.isAppReady = false;
          if(state.redirectUrl) {
            navigate(state.redirectUrl);
          } else {
            navigate("/");
          }
        }
        setState("isLoading", false);
      }).catch((error) => {
        if(error.response) {
          if(error.response.status === httpResult.WRONG_USERNAME_OR_PASSWORD) {
            createNotification(t("email_or_password_invalid"), "error");
            setState("isLoading", false);            
          } else {
            createNotification(t("something_went_wrong"), "error");
            setState("isLoading", false);        
          }
        }
      });
    } else {
      createNotification(t("please_fill_fields"), "error");
    }
  };
  
  const onInputChange = (name: any, value: string) => {
    setState(name, value);
  };
  
  const handleForgotPassword = () => {
    navigate('/auth/reset-password-request');
  };
  
  const handleLoginAdmin = () => {
    const adminUrl = getAppEnvironmentApiData().adminUrl;
    window.location.href = adminUrl;
  };

  const handleChangeGitLabEnvironment = (environment: any) => {
    window.location.href = environment.url + "/auth/logout";
  };

  const handleChangeApiServer = (api: any) => {
    setState("currentApiServer", getCurrentApiServer(apiServersList, api.url));
    setAppData({apiServer: api.url});
  };

  const handleSSOLoginWithQrCode = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setSSOLoginQrCodeModal(settings));
  };

  const handleTitle = useCallback((location: any) => {
    const url = location.substring(1);
    let variable = "";
    if(url.length === 0) {
      variable = 'title_loading';
    } else {
      let tempVariable = url.replaceAll('/', '_').replaceAll('-', '_');
      if(i18next.exists(tempVariable)) {
        variable = tempVariable;
      } else {
        while (i18next.exists(tempVariable) === false && tempVariable !== "title") {
          let tempVariableArray = tempVariable.split('_');
          tempVariableArray.pop();
          tempVariable = tempVariableArray.join('_');
          if (tempVariable === 'title') {
            variable = 'title_nothing_was_found';
          } else {
            variable = tempVariable;
          }
        }   
      }
    }
    return variable;
  }, []);

  useEffect(() => {
    const apiServer = getAppApiServer();
    if(!apiServer) {
      setTimeout(function() {
        setAppData({version: config.APP_VERSION, apiServer: Object.values(apiServers)[0].url});
      }, 1000);
    }
    if(getAppData() !== null && Object.values(apiServers).filter((apiServer) => apiServer.url === apiServer).length === 0) {
      setState("currentApiServer", getCurrentApiServer(apiServersList, Object.values(apiServers)[0].url));
      setTimeout(function() {
        setAppData({version: config.APP_VERSION, apiServer: Object.values(apiServers)[0].url});
      }, 1000);
    }
  }, [apiServers, savedData, setState, state.currentApiServer, apiServersList], []);

  useEffect(() => {
    if((config.APP_ENVIRONMENT === "stage" || config.APP_ENVIRONMENT === "review") && config.GITLAB_KEY){
      getGitLabEnvironments().then((result: any) => {
        if(result) {
          if(result.data) {
            const enviromentsCategories = result.data.map((item: any) => {
              return convertToCapitalFirstLetter(item.name.split("/")[0]);
            }).filter(onlyUnique);
            const environments = enviromentsCategories.map((category: any) => {
              return { name: category, children: result.data.filter((item: any) => convertToCapitalFirstLetter(item.name.split("/")[0]) === category).map((item: any) => {
                const newName = item.name.split("/");
                newName.shift();
                return {
                  name: newName.length === 0 ? parseEnviromentName(item.name) : parseEnviromentName(newName.join(" ")),
                  slug: newName.length === 0 ? item.name : newName.join(" "),
                  url: item.external_url
                };
              })};
            });
            environments.sort((a: any, b: any) => {
              if (a.name === 'Stage' || a.name === 'Production') {
                return -1;
              } else if (b.name === 'Stage' || b.name === 'Production') {
                return 1;
              } else {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
            setState("environments", environments);
          }
        }
      }).catch(() => {
        createNotification(t('gitlab_environment_failed'), 'error');
      });
    }
  }, [setState, t], []);

  const getCurrentGitLabEnvironment = useMemo(() => {
    if(state.environments.filter((item: any) => item.name.toLowerCase() === config.APP_ENVIRONMENT).length !== 0) {
      const environment = state.environments.find((item: any) => item.name.toLowerCase() === config.APP_ENVIRONMENT);
      const children = environment.children;
      if(children.length === 0) {
        return environment;
      } else if(children.length === 1) {
        return environment.children[0];
      } else {
        if(environment.children.filter((item: any) => item.slug === config.BRANCH).length !== 0) {
          return environment.children.find((item: any) => item.slug === config.BRANCH);
        } else {
          return environment;
        }
      }
    } else {
      return null;
    }
  }, [state.environments]);

  return savedUsers.length > 1 ? (
    <Navigate to="/auth/switch" state={{ from: location }}/>
  ) : (
    <div className={classes.loginPage}>
      <div className={classes.loginWrapper}>
        <div className={classes.loginCard}>
          <div className={classes.loginCardAvatarWrapper}>
            <Tooltip title={t('login_teacher_director')} arrow>
              <div className={classes.loginCardAvatar}>
                <SVG src="/resources/images/login-teacher"/>
              </div>
            </Tooltip>
            <Tooltip title={t('login_parent')} arrow>
              <div className={classes.loginCardAvatar}>
                <SVG src="/resources/images/login-family"/>
              </div>
            </Tooltip>
          </div>
          {
            state.isAutoLoggedOff ? (
              <Banner className={classes.banner} type="info" icon={true}>
                {t("autologged_off")}
              </Banner>
            ) : null
          }
          {
            state.redirectUrl ? (
              <Banner className={classes.banner} type="info" icon={true}>
                {t("autoredirect_to", {url: t(`title_${handleTitle(state.redirectUrl)}`)})}
              </Banner>
            ) : null
          }
          <div className={classes.loginCardTitle}>
            {t('login_to_web_application')}
          </div>
          <form className={classes.loginCardForm} onSubmit={handleSubmit}>
            {
              ((state.environments.length > 0 && (config.APP_ENVIRONMENT === "stage" || config.APP_ENVIRONMENT === "review") && config.GITLAB_KEY) || Object.keys(config.API_SERVERS).length > 1) ? (
                <div className={classes.loginCardSelectWrapper}>
                  {
                    (state.environments.length > 0 && (config.APP_ENVIRONMENT === "stage" || config.APP_ENVIRONMENT === "review") && config.GITLAB_KEY) ? (
                      <Select className={classes.select} items={state.environments} selected={getCurrentGitLabEnvironment} setSelected={handleChangeGitLabEnvironment} label={t('gitlab_environment') + '*'} defaultChildTitle='name' disabled={state.isLoading} allowClear={false} dataCy='gitlabEnvironmentSelect'/>
                    ) : null
                  }
                  {
                    Object.keys(config.API_SERVERS).length > 1 ? (
                      <Select className={classes.select} items={apiServersList} selected={state.currentApiServer} setSelected={handleChangeApiServer} label={t('api_server') + '*'} disabled={state.isLoading} allowClear={false} dataCy='apiSelect'/>
                    ) : null
                  }
                </div>
              ) : null
            }
            <Input placeholder={t('email')} type="text" label={t('email') + '*'} name="email" onChange={onInputChange} disabled={state.isLoading} tabIndex="1" autoFocus={true} dataCy="emailInput" useForm={false}/>
            <Input placeholder={t('password')} type="password" label={t('password') + '*'} name="password" onChange={onInputChange} disabled={state.isLoading} tabIndex="2" dataCy="passwordInput" useForm={false}/>
            <div className={classes.footer}>
              <div className={classes.button}>
                <NormalButton tabIndex="4" type="submit" buttonType="primary" dataCy="loginButton" disabled={state.isLoading}>
                  {t("login")}
                </NormalButton>
              </div>
              <div className={classes.bottom}>
                {
                  state.isCameraAvailable ? (
                    <span onClick={handleSSOLoginWithQrCode} data-cy={isCypress() ? "ssoLoginLink" : null}>{t('sso_login_with_qr_code')}</span>
                  ) : null
                }
                <span onClick={handleForgotPassword} data-cy={isCypress() ? "passwordResetLink" : null}>{t('forgot_your_password')}?</span>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.loginCard}>
          <div className={classes.loginCardAvatarWrapper}>
            <Tooltip title={t('login_admin')} arrow>
              <div className={classes.loginCardAvatar}>
                <SVG src="/resources/images/login-admin"/>
              </div>
            </Tooltip>
            <Tooltip title={t('login_mobile')} arrow>
              <div className={classes.loginCardAvatar}>
                <SVG src="/resources/images/login-mobile"/>
              </div>
            </Tooltip>
          </div>
          <div className={classes.loginCardTitle}>
            {t('login_to_administration')}
          </div>
          <NormalButton tabIndex="5" type="button" buttonType="secondary" onClick={handleLoginAdmin} dataCy='loginAdminButton' className={classes.loginHereButton} disabled={state.isLoading}>
            {t('login_here')}
          </NormalButton>
          <div className={classes.loginCardTitle}>
            {t('download_mobile_app')}
          </div>
          <div className={classes.loginCardDownload}>
            <DownloadButton className={'appleAppStore'} tabIndex="-1" title="App Store" subtitle={t('download_on')} link="https://apps.apple.com/app/twigsee/id1478569576" icon="/resources/images/stores/apple_app_store" dataCy="appStoreButton"/>
            <DownloadButton className={'googlePlay'} tabIndex="-1" title="Google Play" subtitle={t('get_it_on')} link="https://play.google.com/store/apps/details?id=mom4moms.picboard" icon="/resources/images/stores/google_play" dataCy="googlePlayButton"/>
            <DownloadButton className={'huaweiAppgallery'} tabIndex="-1" title="AppGallery" subtitle={t('explore_it_on')} link="https://appgallery.huawei.com/#/app/C103676039" icon="/resources/images/stores/huawei_appgallery" dataCy="huaweiAppgalleryButton"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;